.payment-success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: white;
}

.PaymentSuccessContentContainer {
    background-color: white;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.PaymentSuccessValidateContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    width: 50%;
    height: 100%;
    padding: 40px;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .PaymentSuccessValidateContainer {
        display: none;
    }
  }

.ContainerRight {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: 70px;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .ContainerRight {
        width: 100%;
        padding: 30px;
    }
  }

  .PaymentSuccessValidateContainerSmallScreen {
    opacity: 0;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .PaymentSuccessValidateContainerSmallScreen {
        opacity: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 40px;
    }
  }

  .PaymentSuccessSmallScreen {
    color: black;
  }

.PaymentSuccessTitleH1 {
    font-size: 25px;
    font-weight: 600;
    color: white;
}

.PaymentSuccessTitleH1SmallScreen {
    font-size: 25px;
    font-weight: 600;
    color: black;
}

.subtitle {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
    color: white;
}

.subtitleRight {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
}

.PaymentSuccessNextTitle {
    font-size: 27px;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .PaymentSuccessNextTitle {
        font-size: 25px;
    }
  }

.PaymentSuccessSteps {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.PaymentSuccessExplanation {
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.payment-success-icon {
    width: 50px;
    height: 50px;
    margin: 10px;
}

.PaymentSuccessLastSentenceContainer {
    display: flex;
    flex-direction: column;
    margin-top: 40px;
    align-items: center;
    justify-content: center;
}

.PaymentSuccessMarginButton {
    margin-top: 20px;
}