.ParametersPageStylesContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.ParametersPageStylesMainTitle {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 20px;
    text-align: left;
}

.ParametersStylesDescription {
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: start;
}

.ParametersPageStylesTitle {
    font-size: 17px;
    text-decoration: underline;
}

.ParametersStylesEmail {
    margin-left: 5px;
    text-decoration: underline;
}

.ParametersStylesAddMargin {
    margin-top: 40px;
}

.ParametersStylesContainerFAQAffiliation {
    display: flex;
    margin-top: 10px;
}

.ParametersPageStylesContainerButtons{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
  }

  @media screen and (max-width: 768px) {
    .ParametersPageStylesContainerButtons {
        flex-direction: column; 
    }

    .ParametersPageButtonAddMargin {
        margin-bottom: 7px;
    }
  }

  .ParametersPageStylesContainerButtonAndImage {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 768px) {
    .ParametersPageStylesContainerButtonAndImage {
        width: 100%;    
    }
  }

  .ParametersPageStylesEuroIcon {
    width: 15px;
    margin-left: 10px;
  }