div.popup-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

div.popup-modal div.popup-modal-content {
    width: 80%;
    max-width: 400px;
    background-color: white;
    padding: 40px;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: transform 0.3s ease-out;
}

div.popup-modal div.popup-modal-content p {
    font-size: 1.2rem;
    margin-bottom: 30px;
    color: #333;
    line-height: 1.5;
}

div.popup-modal div.popup-modal-content button {
    background-color: #ffffff;
    color: rgb(6, 6, 6);
    border: none;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1rem;
    margin: 4px 2px;
    transition: background-color 0.3s ease;
    cursor: pointer;
    border-radius: 5px;
}

div.popup-modal div.popup-modal-content button:hover {
    background-color: #9e9f9e;
}

div.popup-modal div.popup-modal-content {
    transform: scale(0);
    animation: scaleUp 0.3s forwards;
}

@keyframes scaleUp {
    100% {
        transform: scale(1);
    }
}