.mainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    flex-grow: 1;
    padding-top: 20px;
    max-width: 1000px;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .mainContainer {
        width: 100%;
    }
  }

  .SuprememainContainerHome {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
  }

  .HomeScreenContentContainer {
    width: 100%;
    max-width: 1000px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .HomeScreenContentContainer {
        width: 100%;
    }
  }

  @media (min-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .HomeScreenContentContainer {
      border-radius: 15px;
      margin: 0;
    }
  }

  .HomeScreenSearchBarContainer {
    width: 100%;
    margin-bottom: 30px;
  }
  
  .listContainer {
    display: flex;
    align-items: flex-start; /* Align items from the start of the container */
    justify-content: flex-start; /* Start alignment from the beginning */
    flex-wrap: wrap; /* Wrap items to next line if they don't fit */
    gap: 20px 34px; /* Adjust gap as needed */
    margin-bottom: 20px;
  }

  @media (max-width: 1010px) { /* Adjust 768px to the breakpoint you need */
    .listContainer {
      gap: 20px 33px;
    }
  }

  @media (min-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .listContainer {
      flex-direction: row;
    }
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .listContainer {
      margin-bottom: 120px;
    }
  }
  
  .coachCard {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s;
    width: 100%;
    cursor: pointer;
    padding-bottom: 5px;
  }

  @media (min-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .coachCard {
      width: calc(33% - 20px); /* Adjust width to account for the gap */
      height: 250px; /* Adjust height as needed */
    }
    
  }
  
  .coachCard:hover {
    transform: translateY(-5px); /* Slight lift on hover */
  }
  
  .bannerContainer {
    width: 100%;
    overflow: hidden;
  }
  
  .banner {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  
  .coachInfoContainer {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 5px;
    flex-direction: column;
    height: 100px;
  }
  
  .coachName {
    font-size: 20px;
    font-family: Futura;
  }
  
  .TitleAndDescriptionContainer {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .coachTitle {
    font-size: 16px;
  }
  
  .coachDescription {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
  }

  .bottomPage{
    height: 100px;
  }
  
  .HomeScreenSearchAndMessageContainer {
    display: flex;
  }
  .FirstLayerHomeScreenContentContainer {
   display: flex;
   align-items: flex-end;
   justify-content: space-between;
   margin-bottom: 20px;
  }
  .TitleHomeScreenContentContainer {
    font-size: 35px;
    font-family: Futura;
    padding-top: 20px;
  }
  .chatIconContainer {
    margin-bottom: 5px;
    width: 25px;
    cursor: pointer; /* Cursor change to indicate clickable area */
  }
  .Chat {
    width: 100%; /* Make the logo fill the container width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the logo is scaled properly */
}
  .LogoIconContainerHome {
    align-self: flex-start; /* Align the logo container to the left */
    /* Adjust padding as needed */
    max-width: 140px; /* Adjust the max-width to control the logo size */
    width: 100%; /* Ensure the container takes full width to align the content inside it to the left */
    margin-top: 28px;
    margin-left: 10px;
}

@media screen and (max-width: 950px) {
  .LogoIconContainerHome {
      display: none;
  }
}

.LogoHome {
    width: 100%; /* Make the logo fill the container width */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensure the logo is scaled properly */;
    
}
.leftsideContainerHome {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 20px;
    max-width: 250px;
}
  