.SuprememainContainerStats {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
}

.leftsideContainerStats {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 20px;
    max-width: 250px;
}

.LogoIconContainerStats {
    align-self: flex-start; /* Align the logo container to the left */
    /* Adjust padding as needed */
    max-width: 140px; /* Adjust the max-width to control the logo size */
    width: 100%; /* Ensure the container takes full width to align the content inside it to the left */
    margin-top: 28px;
    margin-left: 10px;
}

@media screen and (max-width: 950px) {
    .LogoIconContainerStats {
        display: none;
    }
  }

.LogoStats {
    width: 100%;
    /* Make the logo fill the container width */
    height: auto;
    /* Maintain aspect ratio */
    object-fit: contain;
    /* Ensure the logo is scaled properly */
    ;

}

.StatsScreenContentContainer {
    width: 100%;
    max-width: 1000px;
    padding-left: 20px;
    padding-right: 20px;
}

.FirstLayerStatsScreenContentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.TitleStatsScreenContentContainer {
    font-size: 35px;
    font-family: Futura;
    padding-top: 20px;
}

.statistics-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    padding-top: 20px;
    max-width: 1000px;
    width: 100%;
    margin-bottom: 100px;
}

.SelectorContainerStats2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: smaller;
}

.SelectorContainerStats1 {
    overflow-y: auto;
    margin-bottom: 30px;
    /* Allows scrolling within the content area if needed */
    /* Adjust this to match your footer's height */
}

.StatsStylesContentContainer {
    /* You can adjust this as needed */
    /* White background for the content container */
    /* Subtle shadow for depth */
    border-radius: 10px;
    /* Rounded corners for the card */
    margin-bottom: 2rem;
    /* Space between cards */
    /* Fixed height, adjust as needed */
    overflow-y: auto;
    /* Enable vertical scrolling if content exceeds container height */
    margin-top: 15px;
}

.StatsStylesContentContainer ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.statistics-container h1 {
    font-size: 2rem;
    color: #333;
    /* Darker color for titles */
    margin-bottom: 1rem;
}

.stat-item {
    background-color: #fafafa;
    /* Slightly different background for each stat */
    padding: 10px;
    margin-bottom: 10px;
    /* Space between stat items */
    border-radius: 5px;
    /* Rounded corners for stat items */
}

.stat-item span {
    font-weight: bold;
    margin-right: 5px;
}

.invalid-data {
    color: #d9534f;
    /* Bootstrap's 'danger' color for invalid data messages */
    font-style: italic;
}

.StatsStylesArrowButtonContainer {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 50px;
    background-color: #F7F8FA;
    border-radius: 50px;
    /* Ensure the container is positioned relatively so the absolute positioned arrow moves in relation to this container */
    position: relative;
}

.StatsStylesTitle {
    font-size: 25px;
    color: black;
    width: 330px;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .StatsStylesArrowButtonContainer {
        margin: 0 0px;
    }

    .StatsStylesTitle {
        width: 200px;
    }
  }

.StatsStylesArrowButton {
    width: 7px;
    height: 14px;
    transition: transform 0.3s ease; /* Smooth transition for 0.3 seconds */
    /* Optionally, position your arrow absolutely if you need more control over its placement within the container */
    position: absolute;
    left: 50%; /* Center the arrow horizontally, adjust as needed */
    top: 50%; /* Center the arrow vertically, adjust as needed */
    transform: translate(-50%, -50%); /* Adjust based on the actual size of your arrow for perfect centering */
}

.StatsStylesArrowButtonLeft {
    width: 7px;
    height: 14px;
    transition: transform 0.3s ease; /* Smooth transition for 0.3 seconds */
    /* Optionally, position your arrow absolutely if you need more control over its placement within the container */
    position: absolute;
    left: 50%; /* Center the arrow horizontally, adjust as needed */
    top: 50%; /* Center the arrow vertically, adjust as needed */
    transform: translate(-50%, -50%); /* Adjust based on the actual size of your arrow for perfect centering */
}

.StatsStylesArrowButtonContainer:hover .StatsStylesArrowButton {
    transform: translate(calc(-50% + 3px), -50%);
}

.StatsStylesArrowButtonContainer:hover .StatsStylesArrowButtonLeft {
    transform: translate(calc(-50% - 3px), -50%);
}

table {

    width: 100%;
    border-collapse: collapse;
    border-radius: 20px;
    border-spacing: 0;
    overflow: hidden;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    /* Shadow for depth */
    margin: 25px 0;
    /* Spacing around the table */
    font-size: 0.9rem;
    /* Font size */
}

.NodataContainerStats {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;

}

.MessageNodata {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 15px;
    font-size: bold;
}

.StatsNoDataExplanation {
    margin-top: 20px;
}

.StatsNoDataIllustration {
    width: 150px;
}


th,
td {
    text-align: center;
    /* Centers text horizontally */
    vertical-align: middle;
    /* Centers content vertically */
    border: 1px solid transparent;
    /* Makes borders transparent to "remove" lines */
    padding: 12px 15px;
    /* Padding inside cells */
    /* Align text to the left */
    border: 1px solid lightgray;
}


th {
    background-color: #005B96;
    /* Light background for headers */
    color: white;
    /* Darker text for headers */
    font-weight: bold;
    /* Bold font for headers */

}

tbody tr:hover {
    background-color: #F7F8FA;
    /* Hover effect for rows */
}

.StatsNavigationContainer {
    display: flex;
    justify-content: center;
    /* Align items to the left and right */
    align-items: center;

    /* Adjust as needed */
    max-width: 960px;
    /* Adjust as needed */
    margin-bottom: 1rem;
    /* Space below the navigation */

}

@media screen and (max-width: 768px) {
    table {
        display: block;
        overflow-x: auto;
        /* Allows horizontal scrolling */
        width: auto;
        /* Adjust width to be auto */
        margin: 10px 0;
        /* Adjust margins for smaller screens */
    }

    th,
    td {
        padding: 8px;
        /* Smaller padding in table cells */
    }
}


/* Content Container Adjustments for Smaller Screens */


@media screen and (max-width: 768px) {
    .statistics-container {
        justify-content: flex-start;
        padding-top: 20px;
        /* Add more padding at the top */
    }

    .StatsStylesContentContainer {
        width: 100%;
    }

    h1,
    .stat-item,
    .StatsStylesArrowButtonContainer,
    th,
    td {
        font-size: smaller;
        /* Adjust font sizes for smaller screens */
    }

    .StatsNavigationContainer {
        flex-direction: row;
        /* Stack items vertically */
        align-items: center;
        /* Center align items */
        margin-bottom: 20px;
        /* Increase bottom margin */
        flex-wrap: wrap;
        width: 100%;
    }
}