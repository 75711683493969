.SuprememainContainerPCreation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;

 
}
.leftsideContainerPCreation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 20px;
  max-width: 250px;
}
.LogoIconContainerPCreation {
  align-self: center; /* Align the logo container to the left */
  /* Adjust padding as needed */
  max-width: 140px; /* Adjust the max-width to control the logo size */
  width: 100%; /* Ensure the container takes full width to align the content inside it to the left */
  margin-top: 28px;
}

.LogoPCreation {
  width: 100%; /* Make the logo fill the container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the logo is scaled properly */;
  
}

.PCreationScreenContentContainer {
  width: 100%;
  max-width: 1000px;
  padding-left: 20px;
  padding-right: 20px;
}

.FirstLayerPCreationScreenContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
 }

 .TitlePCreationScreenContentContainer {
  font-size: 35px;
  font-family: Futura;
  padding-top: 20px;
}

.ProgramCreationStylesLoadingPageAnimation {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ProgramCreationStylesSUPERFULLPageContainer {
    display: flex;
    width: 100%;
    justify-content: center;  
  }

  .ProgramCreationStylesFullPageContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 20px;
    max-width: 1000px;

  }

  @media (max-width: 1200px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesFullPageContainer {
        width: 100%;
    }
  }

  .ProgramCreationStylesContentContainer {
    flex-grow: 1;
    overflow-y: auto; /* Allows scrolling within the content area if needed */
    padding-bottom: 80px; /* Adjust this to match your footer's height */
    width: 90%;
    margin-top: 20px;
  }

  @media (max-width: 950px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesContentContainer {
        width: 100%;
    }
  }
  
  .ProgramCreationStylesContainer {
    width: 100%;
  }
  
  .ProgramCreationStylesScrollContainer {
    flex-grow: 1;
    padding-bottom: 120px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: start;
  }

  @media (max-width: 1200px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesScrollContainer {
        flex-direction: column;
    }
  }

  .ProgramCreationStylesCalendarContainer {
    display: block;
  }

  @media (max-width: 1200px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesCalendarContainer {
        width: 100%;
    }
  }
  
  .ProgramCreationStylesTextContainer {
    margin-top: 20px;
  }
  
  .ProgramCreationStylesText {
    font-size: 16px;
    text-align: center;
    padding: 10px;
  }
  
  .ProgramCreationStylesTitleContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
    
  }
  
  .ProgramCreationStylesTitle {
    font-size: 25px;
    color: black;
  }
  
  .ProgramCreationStylesExerciseContainer {
    width: 100%;
  }

  .ProgramCreationStylesExerciseNameOfEachLine {
    font-size: 14px;
  }

  @media (max-width: 540px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesExerciseNameOfEachLine {
      font-size: 12px;
    }
  }
  
  .ProgramCreationStylesSetContainer {
    display: flex;
    align-items: center;
    margin: 5px;
    margin-bottom: 10px;
  }
  
  .ProgramCreationStylesAddSetButton {
    margin-top: 20px;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 10px;
    align-items: center;
    width: 45%;
  }
  
  .ProgramCreationStylesAddSetButtonContainer {
    align-items: center;
  }
  
  .ProgramCreationStylesButtonContainer {
    display: flex;
    justify-content: space-between;
    margin: 20px 10px;
  }
  
  .ProgramCreationStylesWorkoutContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin-left: 15px;
    width: 100%;
  }

  @media (max-width: 1200px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesWorkoutContainer {
      margin-left: 0px;
      margin-top: 20px;
    }
  }

  .ProgramCreationStylesInfoWorkoutContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    border-radius: 15px;
    padding-left: 15px;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesInfoWorkoutContainer {
      border: 1px solid transparent;
    }
  }
  
  .ProgramCreationStylesHeaderContainer {
    display: flex;
    margin: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  .ProgramCreationStylesHeaderText {
    font-weight: 300;
    font-size: 13px;
    color: black;
  }

  @media (max-width: 520px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesHeaderText {
      font-size: 10px;
      font-weight: 600;
    }
  }

  .ProgramCreationStylesHeaderTextProgramCreationPage {
    font-weight: 600;
    font-size: 13px;
    color: black;
  }
  
  .ProgramCreationStylesInput {
    border: 1px solid gray;
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    color: black;

    padding: 10px 15px; /* Adequate padding for touch targets */
    font-size: 15px; /* Comfortable font size on mobile */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Rounded corners */
    outline: none; /* Remove default focus outline */
    width: 100%; /* Full width with padding considered */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    transition: border-color 0.3s; /* Smooth transition for border color */
  }

  .ProgramCreationStylesInput:focus {
    border-color: #2D2D2D; /* Highlight color when focused */
  }
  
  .ProgramCreationStylesInputWeight {
    border: 1px solid gray;
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    color: black;
  }
  
  .ProgramCreationStylesInputSaved {
    padding: 5px;
    color: black;
    text-align: center;
  }
  
  .ProgramCreationStylesInputSavedWeight {
    padding: 5px;
    color: black;
    text-align: center;
  }
  .setDetailValidated {
    border: 1px solid #5CB85C;
    border-radius: 3px;
    border-radius: 10px;
  }
  
  .setDetailNotValidated {
    border: 1px solid #D9534F;
    border-radius: 3px;
    border-radius: 10px;
  }
  
  .setDetailNotAttempted {
    border: 1px solid #A4A4A4;
    border-radius: 3px;
    border-radius: 10px;
  }
  .ProgramCreationStylesNonEditModeInput {
   
    border-radius: 3px;
    min-height: 40px;
    padding: 5px;
    text-align: center;
    background-color: white;
    padding: 10px 15px; /* Adequate padding for touch targets */
    font-size: 15px; /* Comfortable font size on mobile */
    
    border-radius: 10px; /* Rounded corners */
    outline: none; /* Remove default focus outline */
    width: 100%; /* Full width with padding considered */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    transition: border-color 0.3s; /* Smooth transition for border color */
  }
  
  .ProgramCreationStylesNonEditModeInputWeight {
    border: 1px solid gray;
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    background-color: #F0F0F0;
  }

  @media (max-width: 540px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesNonEditModeInput {
      padding: 10px 0px; /* Reduced padding */
      font-size: 12px; /* Smaller font size */
      min-height: 30px; /* Adjust min-height if necessary */
    }
  }
  
  .ProgramCreationStylesExerciseNameContainer {
    background-color: #005B96;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  .ProgramCreationStylesThreeDotsContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ProgramCreationStylesExerciseName {
    color: white;
    font-weight: bold;
  }

  .ProgramCreationStylesEditAndBlockIconContainer {
    display: flex;
  }
  

  .ProgramCreationStylesEditIconContainer {
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ProgramCreationStylesEditIconContainerDisabled {
    opacity: 0;
    pointer-events: none;
  }
  
  .ProgramCreationStylesEditIcon {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
  
  .ProgramCreationStylesDeleteIcon {
    width: 10px;
    height: 10px;
  }
  
  .ProgramCreationStylesDeleteButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25px;
    height: 25px;
  }
  
  .ProgramCreationStylesCheckboxDisabledOrValidatedIcon {
    width: 15px;
    height: 15px;
  }  
  
  .ProgramCreationStylesSelectExercise {
    margin-top: 10px;
    background-color: black;
    padding: 15px;
    width: 55%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ProgramCreationStylesSelectExerciseText {
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  
  .ProgramCreationStylesSelectExercisePlusIcon {
    margin-left: 5px;
    padding: 5px;
    width: 40px;
    height: 40px;
  }
  
  .ProgramCreationStylesAddExerciseButton {
    margin-top: 10px;
    background-color: black;
    padding: 9px;
    width: 45%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .ProgramCreationStylesAddExerciseButtonText {
    color: white;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }
  
  .ProgramCreationStylesGoToWorkoutEmptyButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .ProgramCreationStylesGoToWorkoutButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    margin-top: 30px;
    width: 100%;
  }

  .ProgramCreationStylesConfigureButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .ProgramCreationStylesConfigureIcon {
    margin-left: 7px;
    height: 27px;
  }

  .ProgramCreationStylesConfigureIconPayment {
    margin-left: 7px;
    width: 16px;
  }

  .ProgramCreationStylesConfigureIconCleetRoue {
    margin-left: 7px;
    width: 13px;
    height: 13px;
  }

  .ProgramCreationStylesConfigureIconVis {
    margin-left: 7px;
    width: 13px;
    height: 13px;
  }

  .ProgramCreationStylesConfigureIconArrow {
    margin-left: 7px;
    width: 13px;
    height: 9px;
  }

  .ProgramCreationStylesStartIcon {
    margin-left: 7px;
    width: 25px;
    height: 28px;
  }

  @media (max-width: 540px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesConfigureIconArrow, .ProgramCreationStylesConfigureIconVis {
      display: none;
    }
  }

  .ProgramCreationStylesGoToWorkoutButton {
    background-color: #F6F7FF;
    padding: 15px;
    border-radius: 10px;
    width: 250px;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.2s;
  }

  .ProgramCreationStylesGoToWorkoutButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesGoToWorkoutButton {
        width: 240px;
    }
  }

  @media (max-width: 488px) { /* Adjust 768px to the breakpoint you need */
    .ProgramCreationStylesGoToWorkoutButton {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
  }
  
  .ProgramCreationStylesGoToWorkoutButtonText {
    color: black;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
  }

  .ProgramCreationStylesExerciseColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    flex: 1;
    min-width: 1px;
  }

  .ProgramCreationStylesSetColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    flex: 1;
  }
  
  .ProgramCreationStylesRepsColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    margin-left: 10px;
    flex: 1;
  }
  
  .ProgramCreationStylesWeightColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    margin-left: 5px;
    flex: 1.3;
  }

  .ProgramCreationStylesRPEColumnContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
    flex: 1;
  }

  .ProgramCreationStylesXContainer {
    display: flex;
    align-items: center;
  }
  
  .ProgramCreationStylesFakeXContainer {
    display: flex;
    align-items: center;
    opacity: 0;
  }
  
  .ProgramCreationStylesDisplayRPE {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1.2;
  }
  
  .ProgramCreationStylesContainerFooter {
    display: flex;
  }
  
  .ProgramCreationStylesNoTrainingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .ProgramCreationStylesNoTrainingText {
    font-size: 16px;
    color: gray;
  }

  .ProgramCreationStylesBottomContainer {
    height: 200px;
  }
  
  .ProgramCreationStylesOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .ProgramCreationStylesLoaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .SelectorContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ProgramCreationStylesSelect {
    display: block;
    width: 100%;
    padding: 12px 14px;
    font-size: 16px;
    line-height: 1.5;
    color: #444;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20xmlns%3D%22http://www.w3.org/2000/svg%22%3E%3Cpath%20fill%3D%22%23444%22%20d%3D%22M7%207l5%205%205-5z%22%3E%3C/path%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 14px top 50%;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-appearance: none; /* Removes default styling of select for Webkit browsers */
    -moz-appearance: none;    /* Removes default styling of select for Mozilla browsers */
    appearance: none;         /* Removes default styling of select for IE browsers */
    border-radius: 10px;
  }
  
  /* Hover effect */
  .ProgramCreationStylesSelect:hover {
    border-color: #888;
  }
  
  /* Focus effect */
  .ProgramCreationStylesSelect:focus {
    border-color: #aaa;
    box-shadow: 0 0 5px rgba(81, 203, 238, 1);
    outline: none;
  }
  
  /* Option styling */
  .ProgramCreationStylesSelect option {
    padding: 8px 10px;
  }
  .SelectorContainerPCreation2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: smaller;

}

.SelectorContainerPCreation1 {
    overflow-y: auto;
    margin-bottom: 30px;
}