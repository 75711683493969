.EditProfileModal {
  position: fixed;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgb(248, 248, 248);
}

.EditProfileModalContentContainer {
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100vh;
  overflow-y: auto; /* Allows vertical scrolling */
  padding-bottom: 200px;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .EditProfileModalContentContainer {
      width: 100%;
      padding-bottom: 200px;
  }
}

.EditProfileModalAddWidth {
  width: auto;
}

.EditProfileSaveIcon {
  width: 15px;
}

.EditProfileModalHeader {
  width: 100%;
  background-color: #FAFAFA; /* Light grey background */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #DBDBDB; /* Light border for separation */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Soft shadow for header */
  z-index: 101; /* Make sure header is above content when scrolling */
}

.EditProfileModalButton {
  background-color: black;
  padding: 10px;
  border-radius: 10px;
}

.EditProfileModalHeader h1 {
  font-size: 18px; /* More moderate size for the title */
  font-weight: 600; /* Semi-bold weight */
  color: #262626; /* Dark grey color for text */
}

.EditProfileModalHeader button:first-child {
  background: none;
  color: black;
}

.EditProfileModalContent {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  margin-top: 20px; /* Provide space from the header */
}

.EditProfileModalContent, .EditProfileModalToggleLine, .EditProfileModalInputContainer label {
  display: block; /* Make label a block element */
  color: #262626;
  font-size: 14px;
}

.EditProfileModalContent input,
.EditProfileModalContent textarea {
  width: 100%; /* Full width inputs */
  padding: 12px;
  font-size: 14px;
  margin-bottom: 16px; /* Spacing between each field */
}

.EditProfileModalContent textarea {
  min-height: 150px; /* Taller textarea for bio */
  resize: none; /* Disable resizing */
}


.EditProfileModalToggleContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.EditProfileModalToggleLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.EditProfileModalDescriptionToggleLine {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.EditProfileModalInputContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.EditProfileModalInputAndPeriod {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditProfileModalPeriodContainer {
  margin-left: 20px;
}

.EditProfileModalPeriod {
  font-size: 14px;
  margin-right: 10px;
}

.EngagementPeriodSelector {
  font-size: 14px;
}

.EditProfileModalToggleTextContainer {
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.EditProfileModalToggleText {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #6c757d; /* Light grey color */
}

/* Styling for the toggle switch */
/* (You can customize it further to fit your design) */
.EditProfileModalToggleSwitch {
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  position: relative;
  outline: none;
  -webkit-appearance: none;
  cursor: pointer;
}

.EditProfileModalToggleSwitch:checked {
  background-color: #4CAF50;
}

.EditProfileModalToggleSwitch::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  top: 1px;
  left: 1px;
  transition: all 0.3s;
}

.EditProfileModalToggleSwitch:checked::after {
  left: 21px;
}

.EditProfileButtonText {
  color: white;
}

.PaymentEditProfileModalAddMarginBottom {
  margin-bottom: 20px;
}