.InviteStudentsMainContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}

.InviteStudentsMainButtonContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.InviteStudentsMainButtonSecondContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.InviteStudentsMainButtonContainerTextAndImage {
    display: flex;
    align-items: center;
    justify-content: center;
}

.InviteStudentsMainLineContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
}

@media screen and (max-width: 950px) {
    .InviteStudentsMainLineContainer {
        display: none;
    }
  }

.InviteStudentFakeRemoveIcon {
    opacity: 0;
    cursor: default;
    margin-right: 5px;
}

.InviteStudentsMainLineNameContainer {
    display: flex;
    width: 20%;
}

.InviteStudentsMainLineGenderContainer {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 10%;
}

.InviteStudentsMainLineEmailContainer {
    display: flex;
    width: 20%;
}

.InviteStudentsMainLineDateContainer {
    display: flex;
    align-items: center;
    width: 20%;
}

.InviteStudentsAddPaddingLeft {
    padding-left: 10px;
}

@media screen and (max-width: 950px) {
    .InviteStudentsMainLineNameContainer, .InviteStudentsMainLineGenderContainer, .InviteStudentsMainLineEmailContainer, .InviteStudentsMainLineDateContainer {
        width: 100%;
        margin-top: 10px;
    }
  }

.InviteStudentsMainLineTitleName {
    font-size: 14px;
    color: grey;
}

.InviteStudentsMainLineTitleGender {
    font-size: 14px;
    color: grey;
}

.InviteStudentsMainLineTitleEmail {
    font-size: 14px;
    color: grey;
}

.InviteStudentsMainLineTitleDate {
    font-size: 14px;
    color: grey;
}

.InviteStudentsContactLineContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

@media screen and (max-width: 950px) {
    .InviteStudentsContactLineContainer {
        margin-bottom: 100px;
        margin-top: 10px;
    }
  }

.InviteStudentsContactLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #F7F8FA;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
}

@media screen and (max-width: 950px) {
    .InviteStudentsContactLine {
        flex-direction: column;
    }
  }

.InviteStudentsInput {
    display: flex;
    padding: 10px 0px;
    padding-left: 10px;
    font-size: 15px;
    border: 1px solid transparent;
    border-radius: 10px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s;
    background-color: #F7F8FA;
}

.InviteStudentsInput:hover {
    border: 1px solid #ccc;
    border-color: #2D2D2D;
    /* Highlight color when focused */
}

.InviteStudentsInput:focus {
    border: 1px solid #ccc;
    border-color: #2D2D2D;
    /* Highlight color when focused */
}

.InviteStudentsInputDate {
    font-size: 15px;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    -webkit-appearance: none; /* Address iOS-specific styling */
    appearance: none; /* Standard way to remove default styling */
    text-align: start;
}

.InviteStudentFieldGroup {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 200px;
}

.AddEmailDatePairButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #F7F8FA;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 10px;
    transition: transform 0.2s;
}

.AddEmailDatePairButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }

.InviteStudentsStylesTrashIcon {
    width: 13px;
    height: 16px;
}

.InviteStudentsInfoIcon {
    width: 15px;
    height: 15px;
    margin-left: 5px;
}

.InviteStudentRemoveButton {
    margin-right: 5px;
}

.InviteStudentsContainerGenderIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

/* Adjustments for Male Icon */
.InviteStudentsMaleIcon {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    display: none; /* Start with icons hidden */
}

/* Adjustments for Female Icon */
.InviteStudentsFemaleIcon {
    width: 15px;
    height: 25px;
    margin-left: 6px;
    display: none; /* Start with icons hidden */
}

/* Show normal icon by default */
.gender-btn .normal {
    display: block;
}

/* Hide normal icon and show selected icon on hover or when selected */
.gender-btn:hover .normal,
.gender-btn.selected .normal {
    display: none;
}

.gender-btn:hover .selected,
.gender-btn.selected .selected {
    display: block;
}

.InviteStudentsInfoIconContainer {
    position: relative;
    display: inline-block;
    cursor: pointer; /* Change cursor to indicate clickable or hoverable */
    display: flex;
    align-items: center;
    justify-content: center;
}

.InviteStudentsInfoIconContainerMobile {
    position: relative;
    display: none;
    cursor: pointer; /* Change cursor to indicate clickable or hoverable */
    display: none;
    align-items: center;
    justify-content: center;
}

.InviteStudentsTooltipText {
    visibility: hidden;
    width: 350px; /* Adjust based on your content */
    background-color: white;
    color: #333;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    
    /* Positioning */
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Adjust based on your needs */
    left: 50%;
    transform: translateX(-50%);
    
    /* Fade in */
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.InviteStudentsInfoIconContainer:hover .InviteStudentsTooltipText, .InviteStudentsInfoIconContainerMobile:hover .InviteStudentsTooltipText {
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 950px) {
    .InviteStudentsTooltipText {
        width: 250px; /* Adjust based on your content */
        left: -100px;
    }
    .InviteStudentsInfoIconContainerMobile {
        display: inline-block;
        display: flex;
    }
  }

  .InviteStudentsLoadingContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 30vh;
  }