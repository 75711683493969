
.FooterIconContainer {
    display: flex;
    flex-direction: row; /* Stacks children vertically */
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    text-align: left;
    width: 100%;
}

.NiceEffectContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 20px; /* Maintain consistency with rounded corners */
    background-color: white; /* Starting background color */
    padding: 0 20px; /* Initial horizontal padding, keep it small */
    margin: 12px 0;
    height: 45px;
    text-decoration: none;
    width: 100%;
    cursor: pointer;
}

.NiceEffectContainerInvite {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 20px; /* Maintain consistency with rounded corners */
    background-color: #005B96; /* Starting background color */
    padding: 0 20px; /* Initial horizontal padding, keep it small */
    margin: 12px 0;
    height: 45px;
    text-decoration: none;
    transition: transform 0.2s;
    cursor: pointer;
}

.NiceEffectContainerInvite:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }

.NiceEffectContainer.active {
    background-color: #f7f8fa; /* Same as hover background */
    text-decoration: none;
    /* Include any other styles you apply on hover that you want to retain for the active state */
}

.FooterIconContainer a:hover,
.FooterIconContainer a:focus, 
.FooterIconContainer a:active {
    text-decoration: none;
}

.NiceEffectContainer:hover,
.NiceEffectContainer:focus, 
.NiceEffectContainer:active {
    background-color: #f7f8fa; /* Light grey background for hover/focus states */
    text-decoration: none;
}

.FooterIcon {
    margin-right: 15px;
    width: 22px;
    height: 25px;
}

.FooterIconEqualSize {
    margin-right: 15px;
    width: 25px;
    height: 25px;
}

.FooterIconInvite {
    margin-left: 10px;
    width: 20px;
    height: 22px;
}

.Footer {
    display: flex;
    flex-direction: column;
    justify-content:  start;
    align-items: center;
    padding-top: 40px;
}

.FooterActiveText {
    color: black;
    font-size: 16px;
    font-family: Futura;
    display: inline-block;
    vertical-align: middle; /* Align the text vertically */
    text-decoration: none;
}

.FooterInactiveText {
    color: black;
    font-size: 16px;
    font-family: Futura;
    text-decoration: none;
}

.FooterActiveTextInvite {
    color: white;
    font-size: 16px;
    font-family: Futura;
    display: inline-block;
    vertical-align: middle; /* Align the text vertically */
    text-decoration: none;
}

.FooterInactiveTextInvite {
    color: white;
    font-size: 16px;
    font-family: Futura;
    text-decoration: none;
}


@media screen and (max-width: 480px) {
    .FooterActiveText, .FooterInactiveText {
        font-size: 10px; /* Smaller font size for text */
    }
}

@media screen and (max-width: 950px) {
    .Footer {
        position: fixed; /* Or 'absolute', depending on your layout */
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-around; /* This spreads out the icons evenly */
        align-items: center;
        background-color: #FFF; /* Or any color that matches your design */
        box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Optional: adds a slight shadow for depth */
        padding: 10px 0; /* Adjust padding to your liking */
        z-index: 1000; /* Ensures the footer is above other content */
    }

    /* Hides the 5th child onwards in the footer, as previously described */
    .FooterIconContainer:nth-child(n+5) {
        display: none;
    }

    .FooterIconContainer {
        justify-content: center;
    }

    .NiceEffectContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .NiceEffectContainer.active {
        background-color: white; /* Same as hover background */
    }
    
    .FooterIcon {
        margin-right: 0px;
        margin-bottom: 5px;
    }
}