.ParrainageContainerMain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.ParrainageSearchContainer {
  display: flex;
  width: 100%;
}
  
  .ParrainageResultsContainer {
    position: absolute;
    top: 50px;
    margin-top: 10px;
    max-height: 150px; /* Adjust based on your needs */
    width: 100%; /* Matching width with the search bar */
    margin-left: auto; /* Aligning with the search bar */
    margin-right: auto; /* Aligning with the search bar */
    overflow-y: auto; /* Adds scrollbar if content exceeds max-height */
    z-index: 1000; /* Ensure it appears above other content */
    background-color: white;
  }
  
  .ParrainageCard {
    background-color: #ffffff; /* White background */
    width: 100%; /* Full width */
    border-radius: 10px; /* Rounded corners */
    padding: 10px; /* Comfortable padding */
    margin: 8px 0; /* Spacing between cards */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    display: flex;
    align-items: center;
    cursor: pointer; /* Indicating that it's clickable */
  }
  
  .ParrainageSearchSpinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-left-color: black;
    animation: spin 1s ease infinite;
    text-align: center;
    margin-left: 15px;
    margin-top: 10px;
  }
  
  .ParrainageNoUserFoundMessage {
    width: 100%; /* Full width */
    border-radius: 10px; /* Rounded corners */
    padding: 10px; /* Comfortable padding */
    margin: 8px 0; /* Spacing between cards */
    display: flex;
    align-items: center;
    font-weight: 400;
  }
  
  .ParrainageName {
    font-size: 18px; /* Slightly larger font size */
    font-weight: bold;
    color: #333333; /* Darker color for better readability */
    margin-left: 10px; /* Space between name and profile picture */
  }
  
  .ParrainageProfilePic {
    width: 40px; /* Adjusting size */
    height: 40px; /* Adjusting size */
    border-radius: 20px; /* Circular profile pictures */
    object-fit: cover; /* Ensuring the image fits well */
  }
  
  .ParrainageSearchAndMessageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .ParrainagechatIconContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 20px;  /* Adjust as needed */
    right: 50px; /* Adjust as needed */
    z-index: 10; /* Ensures the icon stays on top */
  }
  .ParrainageChat {
    width: 37px;
    height: 37px;
  }
  