.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000; /* Ensure it's above other elements */
}

.modalContent {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    width: 80%; /* Use a percentage-based width for better responsiveness */
    max-width: 500px; /* Maximum width for larger screens */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalTitreContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

.WeekCopyModalTitreContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;
}

.modalSousTitreContainer {
    display: flex;
    margin-bottom: 10px;
}

.modalDateFixedWidthContainer {
    display: flex;
    flex-direction: row;
    width: 140px;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .modalDateFixedWidthContainer {
      width: auto;
      text-align: center;
    }
  }

.modalArrowsAndDateContainer {
    display: flex;
}

.modalStartAndEndWeekContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

.modalContainerStartWeek {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #F7F8FA;
    padding: 15px 7px;
    border-radius: 15px;
    margin: 5px;
}

.modalArrow {
    margin-left: 10px;
    margin-right: 10px;
}

.modalWeekCopyChoose {
    text-align: center;
}

.modalCrossContainer {
    margin-bottom: 20px;
}

.ExerciseHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
}

.exerciseListContainer {
    width: 100%;
    max-height: 300px; /* Adjust this value as needed */
    overflow-y: auto; /* This enables scrolling */
    margin-bottom: 20px; /* Optional: adds space before the next element */
    padding-top: 10px;
}

.saveButton {
    background-color: black;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    font-weight: bold;
}

.removeSaveButton {
    background-color: rgb(199, 2, 2);
    color: white;
    padding: 15px 20px;
    border: none;
    border-radius: 10px;
    font-weight: 500;
    transition: transform 0.2s;
}

.removeSaveButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
}

.optionButton {
    background-color: #F7F8FA;
    border: 1px solid #F7F8FA;
    padding: 15px 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    width: 100%;
    transition: transform 0.2s;
  }

 .optionButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
}

.selectedOption {
    border-color: #005B96;
}
.removeSelectedOption {
    border-color: red;
}

.optionText {
    color: black;
    font-size: 16px;
}

.ModalAddExerciseContainer {
    display: flex; /* Aligns children (input + button) in a row */
    justify-content: center; /* Centers the children */
    align-items: center; /* Aligns children vertically */
    gap: 10px; /* Adds space between children */
    margin: 20px; /* Adds some space around the container */
    width: 100%;
  }
  
  .ModalNewExerciseInput {
    flex-grow: 1; /* Allows the input to grow and fill available space */
    border: 1px solid #ccc; /* Adds a border similar to the one in the image */
    border-radius: 10px;
    padding: 15px 20px; /* Adds space inside the input */
    font-size: 16px; /* Sets the font size */
    outline: none; /* Removes the default focus outline */
  }
  
  .ModalNewExerciseInput::placeholder {
    color: #aaa; /* Sets the placeholder text color */
  }
  
  .ModalAddExerciseButton {
    background-color: #F6F7FF; /* Gray background */
    color: black; /* Dark text color for contrast */
    border: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    padding: 15px;
    transition: transform 0.2s;
  }

    .ModalAddExerciseButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
    }
  
  .ModalAddExerciseButton:disabled {
    background-color: #f9f9f9; /* A slightly different background for disabled state */
    color: #ccc; /* Dim the text color when disabled */
    cursor: not-allowed; /* Change cursor to indicate the button is not clickable */
  }

  @media screen and (max-width: 768px) {
    .modalContent {
        width: 75%; /* Slightly more width for small screens */
        padding: 20px; /* Less padding to save space */
    }
    .optionText, .saveButtonText {
        font-size: 15px; /* Smaller font size for smaller screens */
    }
    .optionButton, .saveButton, .ModalAddExerciseButton {
        padding: 10px 15px; /* Smaller padding for buttons */
    }
    .ModalAddExerciseContainer {
        gap: 10px; /* Reduce the gap between input and button */
    }
    .ModalNewExerciseInput, .ModalAddExerciseButton {
        padding: 8px 12px; /* Reduce the padding inside the input and button */
    }
}

/* Further adjustments for very small screens */
@media screen and (max-width: 480px) {
    .modalContent {
        width: 75%; /* Allow modal to take more width on very small screens */
        padding: 20px; /* Reduce padding even more */
    }
    .optionText, .saveButtonText {
        font-size: 14px; /* Even smaller font size for very small screens */
    }
    .ModalAddExerciseContainer {
        flex-direction: column; /* Stack the input and button on top of each other */
        align-items: stretch; /* Make children fill the width */
    }
    .ModalNewExerciseInput, .ModalAddExerciseButton {
        width: 100%; /* Make input and button take full width */
    }
    .ModalAddExerciseButton {
        margin-top: 10px; /* Add space between the input and button when stacked */
    }
}

.noScroll {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: fixed; /* Optional: might be needed for some layouts */
}

.ExerciseSelectionModalStylesAddWidth {
    width: auto;
}

@media screen and (orientation: landscape) and (max-width: 950px) {
    /* This targets devices in landscape mode */
    .modalContent {
      height: auto; /* Adjust height to content or set a max-height */
      max-height: 90%; /* Prevent modal from taking the full screen height */
      overflow-y: auto; /* Enable scrolling within the modal if content exceeds its height */
    }
  
    .modalContainer {
      align-items: center; /* Align modal to the top in landscape mode */
      padding-top: 10vh; /* Add some top padding to ensure modal is visible */
    }
  
    .ModalAddExerciseContainer, .optionButton, .saveButton, .ModalAddExerciseButton {
      /* Adjust button sizes and spacing for landscape */
      padding: 8px 10px;
    }
  
    .optionText, .saveButtonText {
      font-size: 14px; /* Adjust text size for better readability in landscape */
    }
  }