.PayModalStylesContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; /* Center items horizontally */
    background-color: white;
    width: 100%; /* Set width to 100% of the viewport width */
    height: 100%; /* Set height to 100% of the viewport height */
    position: fixed; /* Fixed position */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
}

.PayModalStylesLeftContainer {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    align-items: flex-end;
    justify-content: flex-start;
}

  .PayModalStylesElementLeft {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 450px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 100px;
  }

  .PayModalStylesElementRight {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 450px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 100px;
  }

.PayModalStylesLogo {
    width: 100px;
    height: 30px;
    margin-left: 10px;
}

.PayModalStylesTitleLeft {
    font-size: 33px;
    font-weight: 600;
    display: flex;
    margin-bottom: 16px;
}

.PayModalStylesAddMarginTop {
    margin-top: 30px;
}

.PayModalStylesAddSmallMarginTop {
  margin-top: 16px;
}

.PayModalStylesInputDiv {
    padding: 17px 15px; /* Adequate padding for touch targets */
    font-size: 15px; /* Comfortable font size on mobile */
    border: 1px solid #ccc; /* Subtle border */
    border-radius: 10px; /* Rounded corners */
    outline: none; /* Remove default focus outline */
    width: 100%; /* Full width with padding considered */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    transition: border-color 0.3s; /* Smooth transition for border color */
  }
  
  .card-focused {
    border-color: #2D2D2D; /* Or any color you want for the focus state */
  }

.PayModalStylesDescriptionLeft {
    font-size: 16px;
    color: rgb(107, 107, 107);
    font-weight: 500;
    margin-bottom: 10px;
}

.PayModalStylesSmallLeft {
    font-size: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 50px;
    margin-bottom: 20px;
}

.PayModalStylesRightContainer {
    background-color: white;
    display: flex;
    height: 100vh;
    width: 50%;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Increased blur and slightly more opacity */
}

.PayModalStylesAddGrey {
    color: rgb(70, 70, 70);
    font-weight: 500;
    font-size: 13px;
}

.PayModalStylesTitle {
    display: flex;
    margin-bottom: 20px;
    justify-content: flex-start;
    color: rgb(107, 107, 107);
    font-weight: 500;
    font-size: 16px;
}

.PayModalTextDisabled {
    opacity: 0.5;
}

.PayModalStylesTitleContainer::before,
.PayModalStylesTitleContainer::after {
    content: '';
    flex-grow: 1;
    background: rgb(191, 191, 191); /* Or any color you want */
    height: 1px; /* Adjust the height for thicker lines */
    margin: 0 10px; /* Adjust spacing around the text */
}

.PayModalStylesPricingDetails {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    margin-top: 20px;
    width: 100%;
}

.PayModalStylesPricingDetails .PayModalStylesTotalAmount {
    font-size: 18px; /* Slightly larger font for total */
    font-weight: 500; /* Make the total bold */
    color: black; /* Highlight color for total */
    margin-top: 20px; /* Extra space above the total */
}

.PayModalStylesDetailLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.PayModalStylesDetailLineSeparator {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eaeaea; /* Adjust color as needed */
}

.PayModalStylesInfoAndCardContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.PayModalAddFullWidth {
    width: 100%;
}

.PayModalStylesContainer button:disabled {
    background-color: #ccc;
}

.PayModalStylesPrice {
    font-size: 15px;
}

.PayModalStylesPriceBold {
    font-weight: 500;
    font-size: 17px;
}

.PayModalStylesIntitule {
    font-size: 15px;
    font-weight: 400;
}

.PayModalStylesIntituleBold {
    font-size: 15px;
    font-weight: 500;
}

.PayModalStylesDescriptionSmallGrey {
    font-size: 12px;
    margin-top: 8px;
    color: rgb(107, 107, 107);
}

.PayModalStylesAbonnementContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.PayModalStylesContainerTextAndDescription {
    display: flex;
    flex-direction: column;
}

.PayModalStylesBackButtonContainer {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 10px;
    margin-top: 20px;
    margin-left: 20px;
    z-index: 1000;
  }
  
  .PayModalStylesBackButtonCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .PayModalStylesBackButtonCircle:hover {
    background-color: #e4e4e4; /* Background color on hover */
  }
  
  .PayModalStylesBackButtonImage {
    width: 20px;
    height: 20px;
  }

  .PayModalStylesMarginButton {
    margin-top: 20px;
  }

  .PaymentModalSmallScreenStripeContainer {
    display: none;
  }

  .PayModalStylesButtonDetails {
    display: none;
  }

  .PayModalStylesAddLessDarkBackground {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .PayModalStylesCloseButtonDetailsContainer {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 5px;
  }

  .PayModalStylesCloseButtonDetails {
    color: rgb(107, 107, 107);
    font-size: 13px;
    z-index: 2001; /* Above the modal content */
  }

  .PayModalDetailsArrow {
    width: 15px;
    margin-left: 7px;
  }

  .PayModalStylesButtonTitle {
    background-color: #5841D8;
    padding: 15px;
    border-radius: 10px;
    width: 100%; /* Full width on smaller screens */
    transition: transform 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .PayModalStylesButtonTitle:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }

  .PayModalButtonDisabled:hover {
    transform: none;
  }
  
  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .PayModalStylesContainer {
        padding-top: 0px;
        height: auto;
        flex-direction: column;
        overflow-y: auto; /* Shows scrollbar if content overflows */
    }
    .PayModalStylesLeftContainer {
        position: relative;
        height: auto;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    .PayModalStylesRightContainer {
        width: 100%;
        align-items: center;
        justify-content: center;
        padding-bottom: 100px;
        box-shadow: none;
    }
    .PayModalStylesElementLeft {
        padding-top: 30px;
        align-items: center;
        justify-content: center;
      }
      .PayModalStylesElementRight {
        padding-top: 20px;
      }
      .PayModalStylesSmallLeft {
        display: none;
      }
      .PayModalStylesBackButtonContainer {
        position: absolute; /* Or keep inherit if you have a specific layout in mind */
        margin-top: 3px;
        margin-left: 3px;
      }
      .PayModalStylesPricingDetails {
        margin-bottom: 10px;
      }
      .PaymentModalSmallScreenStripeContainer {
        display: flex;
        margin-top: 20px;
      }
      .PayModalStylesButtonDetails {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgb(244, 244, 244);
        padding: 10px;
        border-radius: 5px;
      }
      .PayModalStylesShowMobile {
        position: fixed;
        padding: 30px;
        top: 0;
        left: 0;
        width: 100%;
        background: white;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
        transition: visibility 0s linear 0.3s, opacity 0.3s ease-in-out, transform 0.3s ease-in-out; /* Delay the visibility transition */
        visibility: hidden; /* Hide the modal, but allow for transition */
        opacity: 0; /* Set to transparent */
        transform: translateY(-100%);
        z-index: 2000;
        overflow-y: auto;
        max-height: 90vh;
    }
    .PayModalStylesShowMobile.active {
        visibility: visible; /* Make the modal visible */
        opacity: 1; /* Set to opaque */
        transform: translateY(0);
        transition-delay: 0s; /* No delay when becoming visible */
    }
    .PayModalStylesButtonTitle {
      width: 100%; /* Adjust button width */
    }
  }