.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
}

.ParametersPaymentModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000; /* Ensure it's above other elements */
}

.safeArea {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    flex-direction: column; /* Arrange children in a column */
    border-radius: 20px;
    padding: 30px;
    transition: all 0.3s ease; /* Smooth transition */
    max-width: 500px; /* Maximum width */
    background-color: white;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .safeArea {
        width: 70%;
    }
  }

.optionContainer {
    padding: 15px 15px; /* Adequate padding for touch targets */
    font-size: 15px; /* Comfortable font size on mobile */
    border: 1px solid transparent; /* Subtle border */
    border-bottom: 1px solid #dcdcdc; /* Style, width, and color for the bottom border */
    border-radius: 10px; /* Rounded corners */
    outline: none; /* Remove default focus outline */
    width: 100%; /* Full width with padding considered */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    transition: border-color 0.3s; /* Smooth transition for border color */
    cursor: pointer; /* Cursor change to indicate clickability */
    margin-bottom: 10px;
} 
  .optionContainer:focus {
    border-color: #2D2D2D; /* Highlight color when focused */
  }

.lastOptionContainer {
    padding: 15px 15px; /* Adequate padding for touch targets */
    font-size: 15px; /* Comfortable font size on mobile */
    border: 1px solid transparent; /* Subtle border */
    border-radius: 10px; /* Rounded corners */
    outline: none; /* Remove default focus outline */
    width: 100%; /* Full width with padding considered */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    transition: border-color 0.3s; /* Smooth transition for border color */
    cursor: pointer; /* Cursor change to indicate clickability */
}

.optionContainer:hover, .lastOptionContainer:hover {
    border-color: #919191; /* Highlight color when focused */
}

@media (min-width: 950px) {
    .desktopHidden {
        display: none;
    }
}