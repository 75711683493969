.App {
  text-align: center;
}

.App-logo {
  height: 100%;
  width: 50%;
  pointer-events: none;
}

@media (min-width: 768px) {
  .App-logo {
    width: 30%;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: text-reveal 3s ease-out forwards;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes text-reveal {
  from {
    clip-path: inset(0 100% 0 0);
    opacity: 0;
  }
  to {
    clip-path: inset(0 0 0 0);
    opacity: 1;
  }
}