.home {
  background-color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .ArrowBackMessageContainer {
    position: absolute;
    top: 0;
    left: 0;
    margin: 20px;
  }

  .containerc {
    border: 1px solid black;
    border-radius: 10px;
    width: 65%;
    height: 80%;
    display: flex;
    overflow: hidden;



    .visible {
      display: block;
      /* Or flex, depending on your layout */
    }

    .hidden {
      display: none;
    }

    .SideBarChat {
      flex: 1;
      background-color: rgb(244, 241, 241);

      .SearchBarChat {
        border-bottom: 1px solid gray;


        .SearchForm {
          padding: 10px;


          input {
            background-color: transparent;
            border: none;
            color: black;
            outline: none;
          }
        }
      }

      .UserChat {
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: black;
        cursor: pointer;

        &:hover {
          background-color: black;
          color: white;
        }




        img {
          height: 40px;
          width: 40px;
          border-radius: 50%;
          object-fit: cover;



        }

        .userChatInfo {
          span {
            font-size: 18px;
            font-weight: normal;
          }

          p {
            font-size: 14px;
            color: lightblack;
          }
        }
      }

      .NavBarChat {
        display: flex;
        align-items: center;
        background-color: black;
        height: 70px;
        padding: 10px;
        justify-content: space-between;
        color: white;
        font-weight: bold;
        border-right: 2px solid white;


        .logo {
          height: 40px;
          width: 40px;
        }

        .Utilisateur {
          display: flex;
          gap: 10px;
          align-items: center;

          img {
            background-color: white;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }
    }
    .ChatChat {
      flex: 2
    }

    .ChatInfo {
      height: 70px;
      background-color: black;
      display: flex;
      align-items: center;
      color: white;
      font-weight: bold;
      justify-content: flex-end;
      padding: 10px;

      .back-arrow {
        display: none;
        /* Hide by default */
        padding: 10px;
        /* Add padding for touch friendliness */
      }

      .back-arrow img {
        width: 20px;
        /* Adjust to your preferred size */
        height: auto;
        /* Maintain aspect ratio */
        display: block;
        margin-left: 10px;
        /* Align to the left */
      }

      @media screen and (max-width: 768px) {
        .back-arrow {
          display: block;
          /* Show on small screens */
          text-align: left;
        }
      }

    }

    .MessagesChat {
      background-color: white;
      padding: 10px;
      height: calc(100% - 140px);
      overflow: scroll;

      .MessageChat {
        display: flex;
        gap: 20px;
        margin-bottom: 20px;

        .MessageInfo {
          display: flex;
          flex-direction: column;
          color: gray;
          font-weight: 300;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
          }


        }

        .subscription-link {
          cursor: pointer;
          text-decoration: underline;
        }

        .MessageContent {
          max-width: 80%;
          display: flex;
          flex-direction: column;
          gap: 10px;


          p {
            background-color: black;
            color: white;
            padding: 10px 20px;
            border-radius: 0px 10px 10px 10px;
            max-width: max-content;



          }

          img {
            width: 50%;

          }


        }

        &.owner {
          flex-direction: row-reverse;

          .MessageContent {
            align-items: flex-end;

            p {
              background-color: lightgray;
              color: black;
              border-radius: 10px 0px 10px 10px;



            }


          }


        }

      }
    }

    .InputChat {
      height: 70px;
      background-color: white;
      padding: 10px;
      border-top: 1px solid rgba(0, 0, 0, 0.334);
      display: flex;
      align-items: center;
      justify-content: space-between;

      input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 16px;



        &::placeholder {
          color: lightgray;

        }
      }

      .send {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: bold;

        img {
          height: 25px;
          width: 25px;
          cursor: pointer;

        }

        button {
          border: none;
          padding: 10px 15px;
          color: white;
          background-color: black;
          border-radius: 10px;
        }
      }
    }
  }
}

/* Media Queries for Mobile Devices */
@media screen and (max-width: 768px) {
  .home {
    padding: 0;
    align-items: stretch;
    /* Stretch to full height */
  }

  .containerc {
    width: 100%;
    /* Full width for small screens */
    height: 100vh;
    /* Full viewport height */
    flex-direction: column;
    /* Stack elements vertically */
  }

  .UserChat img,
  .NavBarChat .Utilisateur img,
  .MessageChat .MessageInfo img {
    height: 30px;
    /* Smaller height for mobile */
    width: 30px;
    /* Smaller width for mobile */
  }

  .MessageChat .MessageContent img {
    width: 100%;
    /* Full width within the message content */
    height: auto;
    /* Maintain aspect ratio */
  }

  .visible {
    display: block;
    /* Or flex, depending on your layout */
  }

  .hidden {
    display: none;
  }

  .ChatChat {
    width: 100%;
    /* Full width for ChatChat */
    height: 100%;
    /* Take full height of the container */
    display: flex;
    flex-direction: column;
    /* Organize contents vertically */

  }


  .MessagesChat {
    flex-grow: 1;
    /* Allow MessagesChat to take up available space */
    overflow-y: auto;
    /* Enable vertical scrolling */
    /* Add other styling as needed */
  }

  .InputChat {

    /* Keep InputChat at the bottom */
    /* Adjust styling as needed */
    .send img {
      height: 20px;
      /* Smaller height for mobile */
      width: 20px;
      /* Smaller width for mobile */
    }
  }

  .containerc.hidden .SideBarChat {
    display: none;
    /* Hide the sidebar when containerc has 'hidden' class */
  }

  .containerc.hidden .ChatChat {
    display: block;
    /* Show ChatChat when containerc has 'hidden' class */
  }

  .containerc .SideBarChat {
    display: block;
    /* Show the sidebar when containerc does not have 'hidden' class */
  }

  .containerc .ChatChat {
    display: none;
    /* Hide ChatChat when containerc does not have 'hidden' class */
  }


  /* Add additional responsive adjustments for other elements within ChatChat */
}