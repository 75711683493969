.fullPageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.fullPageContainerMobileSignUp {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media (max-width: 1300px) {
  .fullPageContainerMobileSignUp {
    margin-bottom: 60px;  
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%; /* Full width on smaller screens */
  box-sizing: border-box;
  margin-left: 50px;
  margin-right: 30px;
  max-height: 90vh; /* Optional: Ensures the image container doesn't exceed the viewport height */
}

@media (max-width: 600px) {
  .container {
    width: 100%; /* Adjust the width for larger screens */
    margin-left: 20px;
    margin-right: 20px;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
  
  .logo {
    width: 200px;
    height: 60px;
  }

  @media screen and (max-width: 480px) {
    .logoContainer {
      position: fixed; /* Fix position at the top */
      top: 0; /* Align to the top */
      left: 0; /* Align to the left */
      width: 100%; /* Full width */
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10; /* Ensure it's above other elements */
      border-bottom: 1px;
      border-bottom-color: gray;
      border-style: solid; /* This line is required to make the border visible */
      background-color: white;
      padding: 20px 0; /* Some padding at the top and bottom */
    }

    .logo {
      width: 100px;
      height: 30px;
    }
  }

  .titleAndDescriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .title {
    font-size: 35px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    color: black;
  }

  @media (max-width: 1024px) {
    .titleAndDescriptionContainer {
      justify-content: center;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    .title {
      text-align: center;
      }
  }
  
  .description, .descriptionPR {
    font-size: 16px;
    color: #666;
  }
  
  .description {
    margin-bottom: 30px;
  }
  
  .buttontitle {
    background-color: black;
    padding: 15px;
    border-radius: 10px;
    width: 100%; /* Full width on smaller screens */
    transition: transform 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .buttontitle:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }

  .BlueButton {
    background-color: #005B96; /* Gray background */
    color: white; /* Dark text color for contrast */
    border: none;
    padding: 12px 22px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.2s;
    width: 100%;
    font-weight: 500;
  }

  .VioletPaymentButton {
    background-color: #5841D8; /* Gray background */
    color: white; /* Dark text color for contrast */
    border: none;
    padding: 12px 22px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.2s;
    width: 100%;
    font-weight: 500;
  }

  .BlueButtonAddPadding {
    padding: 20px;
  }

  .BlueButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }
  
  .VioletPaymentButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }

  .buttonDisabled {
    opacity: 1;
    cursor: not-allowed;
    transition: transform 0.2s;
  }
  
  .buttonText, .buttonTextDisabled {
    color: white;
    font-weight: 600;
  }
  
  .label {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .labelAndDescriptionContainer {
    display: flex;
    flex-direction: column;
  }

  .descriptionLabel {
    margin-top: 5px;
    margin-bottom: 2px;
    font-size: 15px;
  }
  
  .input {
    margin-top: 7px;
    height: 50px;
    width: 30px;
    border-color: gray;
    border-width: 1px;
    text-align: left;
  }

  .hiddenLabel, .hiddenInput {
    opacity: 0;
    pointer-events: none; /* Prevent interaction with the hidden element */
  }

  .inputWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .currencySymbol {
    position: absolute;
    right: 10px; /* Adjust this value as needed */
    top: 56%;
    transform: translateY(-50%);
    color: #999; /* Optional: change the color of the symbol */
    pointer-events: none; /* Prevent the symbol from blocking input */
  }
  
  .inputWrapper.hiddenInput .currencySymbol {
    opacity: 0;
  }
  
  .fieldContainer {
    width: 100%; /* Full width on smaller screens */
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }

  .fieldMargin {
    margin-top: 20px;
  }

  .forgotPasswordButton {
    position: absolute;
    right: 15px; /* Adjust as needed */
    top: 68%; /* Center vertically */
    transform: translateY(-50%);
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 14px;
  }

  .commonStylesButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .contentContainer {
    padding: 16px;
    justify-content: center;
    align-items: center;
  }
  
  .centeredView {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modalView {
    margin: 20px;
    background-color: white;
    border-radius: 20px;
    padding: 35px;
    align-items: center;
    /* Shadow styles can be translated to box-shadow in CSS */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }
  
  .modalText {
    text-align: center;
  }
  
  .optionButton {
    margin-bottom: 20px;
  }
  
  .backContainer {
    width: 30px;
    height: 30px;
    align-items: flex-start;
    justify-content: center;
  }
  
  @media screen and (min-width: 768px) {
    .container {
      width: 100%; /* Adjust the width for larger screens */
      max-width: 600px; /* Adjust maximum width for larger screens */
    }
  
    .fieldContainer {
      width: 100%; /* Adjust field width for larger screens */
    }
  
    .buttontitle {
      width: 100%; /* Adjust button width */
    }
  }
  
  @media screen and (min-width: 1024px) {
    .container {
      width: 100%; /* Adjust the width for very large screens */
      max-width: 500px; /* Adjust maximum width for very large screens */
    }
  }

  .toggleSignUpButton {
    margin-top: 12px;
  }

  .spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinnerWaitStripe {
    border: 2px solid rgba(0, 0, 0, 0.1);
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border-left-color: #fff;
    animation: spin 1s ease infinite;
    text-align: center;
  }

  .drop-highlight {
    border: 2px dashed #007bff; /* Blue dashed border */
    margin: 5px 0; /* Adds some space above and below the highlight */
    background-color: red; /* Light blue background */
    /* Adjust the height as needed to match your item's height or as a placeholder */
    height: 50px; /* Example height, adjust based on your needs */
    border-radius: 5px; /* Optional: adds rounded corners */
    /* Add more styling here to match the look and feel of your application */
}

.invalid-drop-target {
  background-color: #ffcccc; /* Light red, or any indication that dropping is not allowed */
  border: 2px dashed red;
  /* Additional styling to indicate disallowed drop */
}
