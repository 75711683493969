.SuprememainContainerWorkout {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
}

.leftsideContainerWorkout {
  display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 20px;
    max-width: 250px;
}

.LogoIconContainerWorkout {
  align-self: flex-start; /* Align the logo container to the left */
  /* Adjust padding as needed */
  max-width: 140px; /* Adjust the max-width to control the logo size */
  width: 100%; /* Ensure the container takes full width to align the content inside it to the left */
  margin-top: 28px;
  margin-left: 10px;
}

@media screen and (max-width: 950px) {
  .LogoIconContainerWorkout {
      display: none;
  }
}

.LogoWorkout {
  width: 100%;
  /* Make the logo fill the container width */
  height: auto;
  /* Maintain aspect ratio */
  object-fit: contain;
  /* Ensure the logo is scaled properly */
  ;
}

.WorkoutScreenContentContainer {
    width: 100%;
    max-width: 1000px;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 950px) { /* Adjust 768px to the breakpoint you need */
  .WorkoutScreenContentContainer {
      width: 100%;
  }
}

.WorkoutScreenContentCenterContainer {
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.FirstLayerWorkoutScreenContentContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.TitleWorkoutScreenContentContainer {
    font-size: 35px;
    font-family: Futura;
}  
  .WorkoutStylesGlobalContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
    padding-top: 20px;
    max-width: 1000px;
    width: 100%;
  }
  
  .WorkoutStylesScrollContainer {
    padding-bottom: 100px;
    width: 60%;
    max-width: 1000px;
  } 

  /* Add this media query */
@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .WorkoutStylesScrollContainer {
      width: 100%;
  }
}

  .WorkoutStylesBackButtonContainer{
    font-size: 35px;
    font-family: Futura;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .WorkoutStylesDateContainer {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    flex: 1;
    margin-bottom: 20px;
    
  }

  .WorkoutStylesSpinner {
      border: 4px solid rgba(0, 0, 0, 0.1);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border-left-color: white;
      animation: spin 1s ease infinite;
      text-align: center;
  }
  
  .WorkoutStylesDateDayName {
    font-size: 15px;
    color: black;
    margin-bottom: 5px;
  }
  
  .WorkoutStylesDateMonthName {
    font-size: 25px;
    color: black;
    font-weight: bold;
    font-family: Futura;
  }

  .WorkoutStylesTitleContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }

  .WorkoutStylesNotesContainer {
    position: relative; /* This makes the child absolute positioning relative to this container */
    display: flex;
    flex-direction: row;
  }

  .WorkoutStylesNotes {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 5px;
  }

  .WorkoutPointerCursor {
    cursor: pointer;
  }

  .WorkoutStylesInputField {
    position: absolute;
    bottom: -100px; /* Position it below the icon */
    right: 0; /* Align the input field's right edge with the container's right edge */
    left: 100; /* Align the input field's left edge with the container's left edge */
    width: 300px; /* Let it naturally fill the space between the right and left edges */
    padding: 8px 12px; /* Padding inside the input */
    margin-top: 8px; /* Space above the input field */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    transition: border-color 0.3s, box-shadow 0.3s;
    z-index: 10; /* Ensure the input field is above other elements */
    font-family: Arial, Helvetica, sans-serif;
  }

  .WorkoutStylesInputSaved {
    color: black;
    text-align: center;
  }
  
  .WorkoutStylesInputField:focus {
    border-color: black;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    outline: none;
  }
  
  .WorkoutStylesDetailedSet {
    font-size: 12px;
  }

  .WorkoutStylesDetailedSetContainer {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .WorkoutStylesWorkoutSetContainer {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    margin-bottom: 5px;
    border-width: 0.5px;
    border-radius: 5px;
    border-color: black;
  }

  .WorkoutStylesButtonBackModal {
    position: 'absolute'; 
      top: '10px';
      left: '10px'; 
      border: 'none'; 
      cursor: 'pointer';
      display: 'flex';
      align-Items: 'center';
      justify-Content: 'center';
      margin: 0;
      padding: 0;
  }
  .WorkoutStylesModalVideo{
    width: 100%;
    height: 100%;

  }
  @media screen and (max-width: 768px) { /* Adjust the max-width as needed */
    .WorkoutStylesButtonBackModal {
      position: absolute;
      top: 5px; /* Adjust as needed */
      left: 5px; /* Adjust as needed */
      z-index: 1000;
      
    }
    .WorkoutStylesModalVideo {
      width: 100%;
      height: auto;
      margin-top: 40px;
      
      /* Adjust other styling as needed */
    }
  
  }
  
  .WorkoutStylesRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .WorkoutStylesExerciseContainerWorkout {
    margin-bottom: 10px;
    width: 100%;
  }
  
  .WorkoutStylesRowContainerMainSet {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 0.1px;
    border-radius: 5px;
    border-color: #F7F8FA;
    background-color: #F7F8FA
  }
  
  .WorkoutStylesCheckboxContainer {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .WorkoutStylesMainSet {
    font-weight: bold;
    text-align: center;
    font-size: 14px;
  }

  @media (max-width: 520px) { /* Adjust 768px to the breakpoint you need */
    .WorkoutStylesMainSet {
      font-size: 12px;
      font-weight: bold;
    }
  }
  
  .WorkoutStylesContainertitre {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 40px;
    background-color: black;
    padding: 10px;
    border-radius: 15px;
  }
  
  .WorkoutStylesCommentInput {
    border-color: gray;
    border-width: 1px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    /* Adjust text alignment for multiline input */
    text-align: start;
    vertical-align: top;
  }
  
  .WorkoutStylesFakeLottie {
    font-weight: 300;
    font-size: 13px;
  }
  
  
  .WorkoutStylesFakeLottieContainer {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .WorkoutStylesMainSetContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .WorkoutStylesInputContainer {
    display: flex;
    justify-content: center;
  }
  
  .WorkoutStylesHeaderContainerWorkout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .WorkoutStylesHeaderSetContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .WorkoutStylesRPEColumnContainerWorkout {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
    margin-left: 12px;
    flex-grow: 0.2;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }

  .WorkoutStylesStateColumnContainerWorkout {
    display: flex;
    margin-left: 10px;
    flex-grow: 0.2;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }
  
  .WorkoutStylesDisplayRPE {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-weight: bold;
  }

  .WorkoutStylesExerciseColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    flex-grow: 0.5;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }

  .WorkoutStylesMainExerciseContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .WorkoutStylesSetColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    flex-grow: 0.2;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }
  
  .WorkoutStylesFakeXContainer {
    display: flex;
    align-items: center;
    opacity: 0;
  }

  .WorkoutStylesXContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
  }
  
  .WorkoutStylesRepsColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
    flex-grow: 0.3;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .WorkoutStylesRepsColumnContainer {
      flex-grow: 0.4;
    }
  }
  
  .WorkoutStylesWeightColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
    flex-grow: 0.3;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }

  .WorkoutStylesReposColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
    flex-grow: 0.3;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }

  @media (max-width: 520px) {
    .WorkoutStylesSetColumnContainer,
    .WorkoutStylesRepsColumnContainer,
    .WorkoutStylesWeightColumnContainer,
    .WorkoutStylesReposColumnContainer,
    .WorkoutStylesStateColumnContainerWorkout {
       margin-right: 5px;
       margin-left: 5px;
    }
 }

  .WorkoutStylesTitleComponents {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 100px;
  }

  .WorkoutStylesChronoSection {
    display: flex;  
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}
.exerciseCompleted {
  background-color: #5CB85C; /* Green for completed */
}

.exerciseNotCompleted {
  background-color: #D9534F; /* Red for not completed */
}

.exerciseNotAttempted {
  background-color: #005B96; /* Yellow for not attempted */
}
  