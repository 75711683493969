.MainContainerFAQ {
    flex-direction: column;
}

.ContainerBothSidesFAQ {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 30px;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .ContainerBothSidesFAQ {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
  }
}

.ContainerTopFAQ {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 230px;
    background-image: url('../assets/FAQ.png');
    background-size: cover; /* Cover the entire area of the container */
    background-position: center; /* Center the background image */
}

.ContainerTitleDescriptionTopFAQ {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    justify-content: center;
}

.ContainerLeftFAQ {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 30%;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .ContainerTitleDescriptionTopFAQ, .ContainerLeftFAQ {
    margin-left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .ContainerLeftFAQ {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: left;
    width: 100%;
  }
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .ContainerTitleDescriptionTopFAQ {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.SmallTitleFAQ {
    font-size: 16px;
    color: blue;
}

.BigTitleFAQ {
    font-size: 50px;
    margin-top: 10px;
}

.SmallDescriptionFAQ {
    font-size: 14px;
    margin-top: 15px;
    color: #383838;
}

.faq-container {
    max-width: 800px;
    width: 60%;
    margin: 0 auto;
    padding: 0 20px;
    margin-bottom: 60px;
  }

  .TitleFAQTest {
    margin: 20px 0px;
  }

  .faq-Title {
    font-size: 25px;
    color: #5a5a5a;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .faq-container {
      width: 100%;
      padding: 0 0px;
    }
  }
  
  .faq-item {
    overflow: hidden;
  }
  
  .faq-question {
    padding: 15px 0px;
    margin: 0;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
  }
  
  .faq-answer {
    padding: 15px 0px;
    margin: 0;
    color: #5a5a5a;
  }
  
  .arrow {
    transition: transform 0.3s ease;
    display: inline-block;
    transform: rotate(0); /* Arrow points down */
    margin-left: 20px;
  }
  
  /* Rotate arrow to the right when the FAQ is not open */
  .faq-question:not(.open) .arrow {
    transform: rotate(-90deg);
  }

  .faq-item.open .arrow {
    transform: rotate(0deg); /* Adjust to point down when open */
}
  
  /* Add transitions for smooth opening/closing */
  .faq-question, .faq-answer {
    transition: background-color 0.3s ease;
  }

  .linkFAQ {
    text-decoration: underline;
  }
  