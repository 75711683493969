.blockConfigurationStylesContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px; /* Spacing between columns */
    padding: 20px;
    flex-wrap: wrap; /* Allows wrapping for smaller screens */
    border-radius: 10px;
    margin-left: 20px;
    background-color: #F7F8FA;
}


@media (max-width: 950px) { /* Adjust 768px to the breakpoint you need */
    .blockConfigurationStylesContainer {
      position: absolute;
      top: 70px; /* Place it just below the icon. Adjust if your icon has a different height or if there's margin/padding */
      left: -250px; /* Center horizontally */
      }
  }

.blockConfigurationStylesTitleAndEditContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.BlockConfigurationStylesSaveDeleteContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blockConfigurationStylesTitle {
    font-size: 18px;
    text-align: center;
}

.blockConfigurationStylesEditIcon {
    width: 17px;
    height: 17px;
    display: flex;
    margin-left: 10px;
}

.weekColumn {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F7F8FA; /* Light grey background for each column */
    border-radius: 10px; /* Rounded corners */
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 200px;
}

.clicktoExpandOrCollapse {
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.expandOrCollapse {
    cursor: pointer;
}

.weekColumn label {
    font-weight: bold;
    margin-bottom: 10px; /* Space between label and input */
    color: #333; /* Dark text for better readability */
    font-size: 15px;
}

.blockConfigurationStylesErrorMessage {
    color: rgb(255, 65, 65);
    margin-top: 10px;
}

.blockConfigurationStylesLoadingContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
}

.blockConfigurationSpinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-left-color: black;
    animation: spin 1s ease infinite;
    text-align: center;
}

.BlockConfigurationStylesContainerNote {
    display: flex;
    align-items: center;
    justify-content: center;
}