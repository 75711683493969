.MainContainerEverythingCalculator {
  background-color: #2F2E2E;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.MainContainerCalculator {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 50px;
}


@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .MainContainerCalculator {
      flex-direction: column;
  }
}

.ContainerTopCalculator {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.BigTitleCalculator {
  font-size: 40px;
  color: white;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
}

.SmallTitleCalculator {
  font-size: 16px;
  color: white;
  text-align: center;
  margin-top: 15px;
  margin-left: 20px;
  margin-right: 20px;
}

.ContainerRightCalculator {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    border-radius: 5%;
    width: 35%;
    height: 70vh;
    margin: 20px;
}

.ContainerLeftCalculator {
    background-color: #3D3D3D;
    border-radius: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 35%;
    height: 70vh;
    margin: 20px;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .ContainerRightCalculator, .ContainerLeftCalculator {
    width: 90%;
    height: 60vh;
  }
}

.calculator {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  margin: auto;
  padding: 20px;
  border-radius: 4px;
}

.TitleCalculator {
  font-size: 23px;
  font-weight: 600;
  margin-bottom: 40px;
  color: white;
}

.ContainerInputCalculator {
  display: flex;
  justify-content: space-between;
}

.SmallDescriptionCalculator {
  font-size: 15px;
  color: white;
}

.NumberCalculator {
  font-size: 15px;
  font-weight: 600;
  color: white;
  margin-left: 20px;
}

.input-group {
  margin-bottom: 15px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.ResultsCalculator {
  font-size: 55px;
  font-weight: 600;
}

.ResultsSmallCalculator {
  font-size: 18px;
  margin-top: 5px;
}

.ResultsBigCalculator {
  font-size: 50px;
}

.ResultsSmallSmallCalculator {
  font-size: 14px;
  margin: 30px;
  text-align: center;
}

.ResultsContainerCalculator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ButonCalculatorWidth {
  width: 80%;
}


