.SearchMainContainerFull {
  display: flex;
  flex-direction: column;
  position: relative;
}

.SearchContainer {
  display: flex;
  align-items: center;
  background-color: white; /* Lighter background for subtlety */
  border: 1px solid #d9d9d9; /* Lighter border */
  border-radius: 15px; /* Rounded corners for a modern look */
  padding: 5px 15px; /* Adjusted padding for a sleeker look */
  width: 100%; /* Slightly narrower for focus */
}

.Input {
  flex: 1;
  border: none; /* Removing border */
  outline: none; /* Removing outline to enhance focus */
  padding: 10px 15px; /* Comfortable padding */
  border-radius: 20px; /* Rounded input field */
  background-color: transparent; /* Transparent background */
  font-size: 16px; /* Slightly larger font size */
}

.IconContainer, .ClearIconContainer {
  background-color: transparent; /* Transparent background */
  border: none; /* No border */
  padding: 5px; /* Reduced padding */
  cursor: pointer; /* Cursor change to indicate clickable area */
}

.SearchIcon, .ClearIcon {
  width: 20px; /* Uniform size for icons */
  height: 20px; /* Uniform size for icons */
}

.ResultsContainer {
  margin-top: 10px;
  width: 100%; /* Matching width with the search bar */
  margin-left: auto; /* Aligning with the search bar */
  margin-right: auto; /* Aligning with the search bar */
  position: absolute;
  z-index: 10000;
  background-color: white;
  margin-top: 50px;
}

.Card {
  background-color: #ffffff; /* White background */
  width: 100%; /* Full width */
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Comfortable padding */
  margin: 8px 0; /* Spacing between cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  display: flex;
  align-items: center;
  cursor: pointer; /* Indicating that it's clickable */
}

.SearchSpinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border-left-color: black;
  animation: spin 1s ease infinite;
  text-align: center;
  margin-left: 15px;
}

.NoUserFoundMessage {
  width: 100%; /* Full width */
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Comfortable padding */
  margin: 8px 0; /* Spacing between cards */
  display: flex;
  align-items: center;
  font-weight: 400;
}

.Name {
  font-size: 18px; /* Slightly larger font size */
  font-weight: bold;
  color: #333333; /* Darker color for better readability */
  margin-left: 10px; /* Space between name and profile picture */
}

.ProfilePic {
  width: 40px; /* Adjusting size */
  height: 40px; /* Adjusting size */
  border-radius: 20px; /* Circular profile pictures */
  object-fit: cover; /* Ensuring the image fits well */
}

.SearchAndMessageContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Chat {
  width: 37px;
  height: 37px;
}

@media (max-width: 768px) {
  
  .Input {
    font-size: 14px; /* Smaller font size */
    padding: 8px 10px; /* Reduced padding */
  }

  .IconContainer, .ClearIconContainer {
    padding: 3px; /* Further reduced padding */
  }

  .SearchIcon, .ClearIcon {
    width: 18px; /* Slightly smaller icons */
    height: 18px;
  }

  .Chat {
    width: 30px; /* Slightly smaller chat icon */
    height: 30px;
    margin-left: 5px;
  }
}
