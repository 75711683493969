  .MySubscriptionsMainContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will push the footer to the bottom */
    min-height: 100vh; /* Full viewport height */
    background-color: rgb(248, 248, 248);
    align-items: center;
  }
  
  .MySubscriptionsLoaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .MySubscriptionsContainer {
    flex-grow: 1;
    overflow-y: auto; /* Allows scrolling within the content area if needed */
    padding-bottom: 80px; /* Adjust this to match your footer's height */
    width: 100%;
    max-width: 1000px;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .MySubscriptionsContainer {
      width: 100%;
  }
}

.ManageSubscriptionsStylesTitle {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.ManageSubscriptionsInviteText {
  font-size: 15px;
}

.FirstLayerManageSubscriptionsScreenContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
 }

.ManagerSubscriptionsInviteTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 950px) { /* Adjust 768px to the breakpoint you need */
  .ManagerSubscriptionsInviteTextContainer {
    display: none;
  }
}

.ManageSubscriptionsInviteIcon {
  width: 17px;
  height: 17px;
  margin-right: 7px;
}

.ManageSubscriptionsStylesMarginTop {
  margin-top: 30px;
}
  
.MySubscriptionsCard {
  justify-content: space-between;
  padding: 8px 0px;
  margin: 8px 0px;
  border-bottom: 1px solid #dcdcdc; /* Style, width, and color for the bottom border */
  display: flex;
  align-items: center;
}

  .MySubscriptionsCardPhotoName {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .MySubscriptionsCancelButton {
    font-size: 14px;
    color: rgb(255, 57, 57);
  }

  .MySubscriptionsCancelledInfo {
    font-size: 14px;
    margin-left: 30px;
  }
  
  .MySubscriptionsProfilePic {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 16px;
    object-fit: cover;
  }
  
  .MySubscriptionsName {
    font-size: 15px;
    font-weight: bold;
    color: #333;
  }
  
  .MySubscriptionsCenteredContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .MySubscriptionsCenteredContainerStudent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80vh;
  }
  
  .MySubscriptionsNoSubscriptionsText {
    font-size: 16px;
    margin-top: 40px;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  
  .MySubscriptionsButton {
    background-color: black;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
  }
  
  .MySubscriptionsButtonText {
    color: black;
    text-align: center;
  }
  
  .ManageSubscriptionStylesSpinnerSmall {
    width: 30px;
    height: 30px;
  }
  