.SuprememainContainerProfile {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
}

@media screen and (max-width: 768px) {
  .SuprememainContainerProfile {
    padding-bottom: 200px;
  }
}

.leftsideContainerProfile {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-grow: 1;
    padding-top: 20px;
    max-width: 250px;
}

.LogoIconContainerProfile {
  align-self: flex-start; /* Align the logo container to the left */
  /* Adjust padding as needed */
  max-width: 140px; /* Adjust the max-width to control the logo size */
  width: 100%; /* Ensure the container takes full width to align the content inside it to the left */
  margin-top: 28px;
  margin-left: 10px;
}

@media screen and (max-width: 950px) {
  .LogoIconContainerProfile {
      display: none;
  }
}

.LogoProfile {
  width: 100%; /* Make the logo fill the container width */
  height: auto; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure the logo is scaled properly */;
  
}

.ProfileScreenContentContainer {
  width: 100%;
  max-width: 1000px;
}

.FirstLayerProfileSearchScreenContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
 }

 .FirstLayerProfileScreenContentContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
 }

 .FirstLayerProfileScreenContentContainerDemo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
 }

 @media screen and (max-width: 768px) {
  .FirstLayerProfileSearchScreenContentContainer {
      display: none;
  }
}
 
 .TitleProfileScreenContentContainer {
   font-size: 35px;
   font-family: Futura;
   display: flex;
   align-items: center;
   justify-content: center;
 }

 @media screen and (max-width: 950px) {
  .FirstLayerProfileScreenContentContainerDemo {
    padding-top: 20px;
  }
}


.ProfilePageBannerContainer {
  width: 100%;
  height: 160px;
  margin-top: 20px;
}

.ProfilePageSearchBannerContainer {
  filter: brightness(90%);
  width: 100%;
  height: 160px;
  margin-top: 20px;
}

.ProfilePageBannerButton {
  background: none; /* No background */
  border: none; /* No border */
  padding: 0; /* No padding */
  width: 100%; /* Ensure it takes the full width */
  display: flex; /* To center the content if needed */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.ProfilePageBannerImage {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 12px;
}

.ProfilePageProfileImage {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  object-fit: cover;
}

.ProfilePageProfileImageContainer {
  position: absolute;
  display: flex;
  margin-top: -50px;
  margin-left: 20px;
}

.ProfilePageNameContainer {
  margin-top: -13px;
  display: flex;
  margin-left: 20px;
  margin-bottom: 5px;
}

.ProfilePageSearchNameContainer {
  display: flex;
  margin-left: 20px;
  margin-bottom: 5px;
}

.ProfilePageContainerMenu{
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.ProfilePageMenuContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
}

.ProfilePageButtonHoverEffect {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfilePageButtonHoverEffect:hover {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F7F8FA;
}

.ProfilePageMenu {
  width: 15px;
  height: 15px;
}

.ProfilePageName {
  font-size: 20px;
  font-family: Futura;
}

.ProfilePageSearchContainerInfoAndSubscribe {
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .ProfilePageSearchContainerInfoAndSubscribe {
      flex-direction: column;
      align-items: center;
  }
}

.ProfilePageSearchContainerLeftContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-right: 40px;
}

@media screen and (max-width: 768px) {
  .ProfilePageSearchContainerLeftContainer {
    width: 100%;
    padding-right: 0px;
  }
}

.ProfilePageSearchMainContainerRight {
  display: flex;
  width: 70%;
}

@media screen and (max-width: 768px) {
  .ProfilePageSearchMainContainerRight {
    width: 100%;
    margin-top: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

.ProfilePageSearchContainerRightContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  background-color: #F6F7FF;
  border-radius: 10px;
  padding: 40px;
  margin-bottom: 20px;
}

.ProfilePageTitleFutura {
  font-family: Futura;
  font-size: 25px;
  margin-bottom: 5px;
}

.ProfilePageDescriptionContainerRight {
  font-size: 15px;
  margin-bottom: 30px;
}

.ProfilePageSearchBenefitsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.ProfilePageSearchBenefitsIcon {
  width: 15px;
  height: 13px;
}

.ProfilePageSearchBenefits {
  font-size: 14px;
  margin-left: 10px;
}

.ProfilePageInfoContainer{
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
}

.ProfilePageInfo{
  font-size: 15px;
  margin-top: 6px;
}

.ProfilePageActionButtons{
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .ProfilePageProfileImageContainer, .ProfilePageSearchNameContainer, .ProfilePageNameContainer, .ProfilePageInfoContainer, .ProfilePageActionButtons {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (max-width: 768px) {
  .ProfilePageActionButtons {
    justify-content: space-between;
  }
}

.ProfilePageSearchButtonShare {
  background-color: #F6F7FF; /* Gray background */
  color: black; /* Dark text color for contrast */
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 20px;
  transition: transform 0.2s;
}

.ProfilePageSearchButton {
  background-color: #F6F7FF; /* Gray background */
  color: black; /* Dark text color for contrast */
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 20px;
  margin-right: 5px;
  transition: transform 0.2s;
}
.ProfilePageSearchButtonWrite {
  background-color: #F6F7FF; /* Gray background */
  color: black; /* Dark text color for contrast */
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  padding: 20px;
  transition: transform 0.2s;
  margin-left: 5px;
}

@media screen and (max-width: 768px) {
  .ProfilePageSearchButton, .ProfilePageSearchButtonWrite, .ProfilePageSearchButtonShare {
    width: 100%;
  }
}

.ProfilePageSearchButton:hover, .ProfilePageSearchButtonWrite:hover, .ProfilePageSearchButtonShare:hover {
  transform: translateY(-3px); /* Slight lift on hover */
}

.ProfilePageAddMarginLeft {
  margin-left: 5px;
}
.ProfilePageAddMarginRight {
  margin-right: 5px;
}

.ProfilePageSearchAddWidth {
  width: 25%;
}

@media screen and (max-width: 768px) {
  .ProfilePageSearchAddWidth {
    width: 100%;
  }
}

.ProfilePageSearchAddTextAlign {
  display: flex;
  justify-content: center;
}

.ProfilePageStylesButtonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ProfilePageStylesAddMargin {
  margin-top: 20px;
}

.ProfilePageAddMarginStripeConfiguration {
  margin-top: 20px;
}

.ProfilePageHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
  }
  
  .ProfilePageHeaderTitle {
    font-size: 22px;
    font-weight: bold;
  }
  
  .ProfilePageHamburgerMenuButton {
    padding: 10px;
  }
  
  .ProfilePageHamburgerIcon {
    width: 24px;
    height: 24px;
  }
  
  .ProfilePageContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will push the footer to the bottom */
    min-height: 100vh; /* Full viewport height */
    background-color: rgb(248, 248, 248);
    align-items: center;
  }

  .ProfilePageContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1000px;
}

@media (max-width: 950px) { /* Adjust 768px to the breakpoint you need */
  .ProfilePageContent {
      width: 100%;
      padding-top: 0px;
      padding-left: 20px;
      padding-right: 20px;
  }
}

.ProfilePageBackArrowContainer {
  margin-left: 10px;
  margin-top: 10px;
  z-index: 1000;
  position: absolute;
}

@media (min-width: 768px) { /* Adjust 768px to the breakpoint you need */
  .ProfilePageBackArrowContainer {
      display: none;
  }
}

  .ProfilePageScrollContainer {
    display: flex;
    flex: 1;
    background-color: white; 
    flex-direction: column;
  }
  
  .ProfilePageActiveIcon {
    color: black;
  }
  
  .ProfilePageEditIcon {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
  
  .ProfilePageIconTitre {
    width: 25px;
    height: 25px;
    margin-bottom: 10px;
  }
  
  .ProfilePageButtontitle, .ProfilePageButtontitle1 {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    background-color: gray;
    padding: 7px;
    width: 55%;
    border-radius: 30px;
    align-self: center;
    align-items: center;
  }
  
  .ProfilePageButtontitle1 {
    margin-bottom: 20px;
  }
  
  .ProfilePageButtonText {
    color: white;
    font-weight: bold;
    margin-left: 17px;
  }
  
  .ProfilePageButtonsContainer {
    display: flex;
    flex: 1;
  }
  
  .ProfilePageTitle {
    font-size: 24px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  .ProfilePageFieldsContainerColumn {
    display: flex;
    flex: 1;
    align-items: center;  
    flex-direction: column;
    margin-top: 30px;
  }
  
  .ProfilePageContainertitre {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding: 10px;
    border-radius: 15px;
  }
  
  .ProfilePageTextetitre {
    font-size: 17px;
    font-weight: bold;
    color: #FFFF00;
  }

  .ProfilePageInputContainerfirstLine,
  .ProfilePageInputContainerMiddleLine,
  .ProfilePageInputContainerThirdLine {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .ProfilePageInputContainerfirstLine {
    background-color: white;
    margin-bottom: -20px;
  }

  .ProfilePageInputContainerMiddleLine {
    margin-left: 30px;
    margin-right: 30px;
  }

  .ProfilePageInputContainerThirdLine {
    background-color: white;
    margin-top: -20px;
  }
  
  .ProfilePageLabel {
    font-size: 16px;
    color: black;
    margin-bottom: 5px;
  }

  .ProfilePageInputContainer {
    display: flex; /* Add this line */
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    flex-direction: column;
    width: 100%;
}

.ProfilePageInput {
    background: none;
    border: none;
    font-size: 22px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: white;
    line-height: normal;
    outline: none;
    width: 100%; /* Fill the width of the container */
    height: 100%; /* Fill the height of the container */
    box-sizing: border-box; /* Include padding and border in the width and height */
    text-align: center; /* Center text horizontally */
}
  
  .ProfilePageKgLabel {
    font-size: 15px;
    color: white;
    font-weight: bold;
  }

  .ProfilePageRowContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px; /* Spacing between elements */
    margin-bottom: 20px;
  }
  
  .ProfilePageMaincircleContainer {
    height: 90px;
    width: 90px;
    border-radius: 45px;
    border-width: 2px;
    background-color: black;
    display: flex;
    align-items: center; /* Vertically center contents */
    justify-content: center; /* Horizontally center contents */
    border-style: solid;
    border-color: black;
    padding: 0; /* Remove padding if any */
}

.ProfilePageSecondaryCircleContainer {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    border-width: 1px;
    display: flex;
    align-items: center; /* Vertically center contents */
    justify-content: center; /* Horizontally center contents */
    border-style: solid;
    border-color: black;
    margin: 0; /* Adjust if needed */
}
  
  .ProfilePageRowContainer {
    display: flex;
    flex-direction: row;
  }
  
  .ProfilePageTopBarContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
    margin-top: 10px;
    border-bottom: 1px solid gray;
  }
  
  .ProfilePageSelectedOption {
    color: black;
  }
  
  .ProfilePageUnselectedOption {
    color: gray;
  }
  
  .ProfilePageProfilCreateurText {
    font-size: 15px;
    color: black;
    text-align: center;
    margin-top: 20px;
  }
  
  .ProfilePageButtonText2 {
    color: #FFFF00;
  }
  
  .ProfilePageButton2 {
    background-color: black;
    padding: 15px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: 40px;
  }
  
  .ProfilePageLoadingContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100vh;
  }
  
  .ProfilePageOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.2);
    z-index: 999; /* Below the modal */
  }

  .spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border-left-color: black;
    animation: spin 1s ease infinite;
    text-align: center;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .bottomPage{
    height: 100px;
  }

