.payment-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; /* Center items horizontally */
    background-color: white;
    width: 100%; /* Set width to 100% of the viewport width */
    height: 100%; /* Set height to 100% of the viewport height */
    position: fixed; /* Fixed position */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
}

.PaymentModalLeftContainer {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100vh;
    align-items: flex-start;
    justify-content: center;
    background-color: black;
    padding: 20px;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .PaymentModalLeftContainer {
        display: none;
    }
  }

.PaymentModalArrowAndLogoContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
}

.PaymentModalLogo {
    width: 100px;
    height: 30px;
    margin-left: 10px;
}

.PaymentModalTitleLeft {
    font-size: 30px;
    font-weight: 600;
    display: flex;
    margin-bottom: 16px;
    color: white;
}

.PaymentModalDescriptionLeft {
    font-size: 15px;
    color: white;
}

.PaymentModalSmallLeft {
    color: white;
    font-size: 12px;
    margin-top: 150px;
}

.PaymentModalRightContainer {
    background-color: white;
    height: 100vh;
    width: 50%;
}

@media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .PaymentModalRightContainer {
        width: 100%;
    }
  }

  .PaymentModalArrowAndLogoContainerSmallScreen {
    display: none;
  }

  .PaymentModalSmallScreenStripeContainer {
    display: none;
  }
  
  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .PaymentModalSmallScreenStripeContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
  }

  .PaymentModalSmallLeftSmallScreen {
    color: black;
    font-size: 12px;
  }

  .PaymentModalBackButtonContainerSmallScreen {
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 20px;
    margin-left: 5px;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .PaymentModalArrowAndLogoContainerSmallScreen {
        display: flex;
        margin-top: 25px;
        align-items: center;
        justify-content: center;
    }
  }

.PaymentModalTitleContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.PaymentModalTitle {
    display: flex;
    margin-bottom: 40px;
    margin-top: 40px;
    justify-content: center;
    color: rgb(138, 138, 138);
}

.PaymentModalTitleContainer::before,
.PaymentModalTitleContainer::after {
    content: '';
    flex-grow: 1;
    background: rgb(191, 191, 191); /* Or any color you want */
    height: 1px; /* Adjust the height for thicker lines */
    margin: 0 10px; /* Adjust spacing around the text */
}

.PaymentModalPricingDetails {
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 50px;
    width: 100%;
}

.PaymentModalPricingDetails span {
    display: block; /* Each span on its own line */
    margin-bottom: 10px; /* Margin between lines */
    font-size: 16px; /* Regular text size */
    color: black; /* Dark text for readability */
}

@media (max-width: 450px) { /* Adjust 768px to the breakpoint you need */
    .PaymentModalPricingDetails span, .PaymentModalIntitule {
        font-size: 15px; /* Regular text size */
    }
  }

.PaymentModalPricingDetails .totalAmount {
    font-size: 18px; /* Slightly larger font for total */
    font-weight: 500; /* Make the total bold */
    color: black; /* Highlight color for total */
    margin-top: 20px; /* Extra space above the total */
}

.PaymentModalDetailLine {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 2px;
    margin-bottom: 2px;
}

@media (max-width: 450px) { /* Adjust 768px to the breakpoint you need */
    .PaymentModalDetailLine {
        margin-top: 4px;
        margin-bottom: 4px;
    }
  }

.PaymentModalInfoAndCardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px;
}

.payment-container button:disabled {
    background-color: #ccc;
}

.PaymentModalPrice {
    font-weight: 500;
}

.PaymentModalIntitule {
    font-style: italic;
}

.PaymentModalIntitulePetit {
    font-style: italic;
    font-weight: 400;
    margin-top: 3px;
    font-size: 13px;
}

.PaymentModalAbonnementContainer {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.PaymentModalBackButtonContainer {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    padding: 0;
  }
  
  .PaymentModalBackButtonCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .PaymentModalBackButtonCircle:hover {
    background-color: #252525; /* Background color on hover */
  }
  
  .PaymentModalBackButtonImage {
    width: 20px;
    height: 20px;
  }

  .PaymentModalMarginButton {
    margin-top: 20px;
  }