/* Reset CSS */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

/* CSS for a beautiful and simple input */
.input {
  padding: 10px 15px; /* Adequate padding for touch targets */
  font-size: 15px; /* Comfortable font size on mobile */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 10px; /* Rounded corners */
  outline: none; /* Remove default focus outline */
  width: 100%; /* Full width with padding considered */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  transition: border-color 0.3s; /* Smooth transition for border color */
}

.input:focus {
  border-color: #2D2D2D; /* Highlight color when focused */
}

/* Placeholder styling */
.input::placeholder {
  color: #888; /* Placeholder text color */
}

/* Responsive input for mobile screens */
@media screen and (max-width: 480px) {
  .input {
    font-size: 14px; /* Slightly smaller font size on very small screens */
    padding: 8px 12px; /* Adjust padding for small screens */
  }
}

button,
textarea,
select {
  font-family: inherit; /* Inherit the font family */
  font-size: 100%; /* Inherit the font size */
  line-height: 1.15; /* Correct the line height */
  margin: 0; /* Remove the margin */
  padding: 0; /* Remove padding */
  color: inherit; /* Inherit the text color */
  background-color: transparent; /* No background */
  -webkit-appearance: none; /* Remove default webkit styles */
  -moz-appearance: none; /* Remove default Mozilla styles */
  appearance: none; /* Remove default styles */
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer; /* Pointer cursor for buttons */
  background: none; /* No background */
  color: inherit; /* Inherit text color */
}

/* Add more element selectors as needed */

a {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Inherits the color from the parent element */
  background-color: transparent; /* Ensures background is transparent */
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
}

a:hover,
a:focus {
  text-decoration: underline; /* Optional: underline on hover/focus for visual feedback */
}