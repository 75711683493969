.PaymentProfilePageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    background-color: #F6F7FF;
    border-radius: 10px;
    padding: 40px;
    margin: 20px;
  }

  .PaymentProfilePageContent {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 1000px;
}

@media (max-width: 950px) { /* Adjust 768px to the breakpoint you need */
  .PaymentProfilePageContent {
      width: 100%;
      padding-top: 0px;
      padding-left: 20px;
      padding-right: 20px;
  }
}

  .PaymentProfilePageContainerEdit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .PaymentProfilePageFinaliserContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .PaymentStylesConfigureButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .PaymentAddWidthButton {
    width: 100%;
  }

  .PaymentProfilePageFinaliserAddPadding {
    padding: 20px;
  }

  .PaymentProfilePageActionButtons{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  @media (max-width: 950px) { /* Adjust 768px to the breakpoint you need */
    .PaymentProfilePageActionButtons {
      flex-direction: column;
      padding-bottom: 100px;
    }
  }

  .PaymentDisabledDiv {
    opacity: 0.5;
    pointer-events: none; /* Prevents clicking on anything within the div */  
  }

  .PaymentProfilePageButtonHoverEffect {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  .PaymentPublicProfileAddMargin {
    margin-bottom: 10px;
  }

  .PaymentStylesConfigureIcon {
    margin-left: 7px;
    height: 20px;
  }
  
  @media screen and (max-width: 768px) {
    .ProfilePageProfileImageContainer, .ProfilePageSearchNameContainer, .ProfilePageNameContainer, .ProfilePageInfoContainer, .PaymentProfilePageActionButtons {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .PaymentProfilePageActionButtons {
      justify-content: space-between;
    }
  }