.DemoGenderStylesMainContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px; /* Add some padding */
}

.DemoGenderStylesTitle {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 50px;
  text-align: center; /* Center title text */
}

.DemoGenderStylesContainerGenderChoice {
  display: flex;
  gap: 20px; /* Space between the gender cards */
  flex-wrap: wrap; /* Allow cards to wrap on smaller screens */
}

.gender-selection-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Fill the screen vertically */
  gap: 20px; /* Space between the gender cards */
  padding: 10px; /* Add some padding */
}

.gender-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px solid transparent; /* Default border, change as needed */
  cursor: pointer;
  transition: all 0.3s ease; /* Smooth transition for hover effects */
}

.gender-card img {
  max-width: 250px; /* Make image responsive */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px;
}

.gender-card:hover {
  transform: translateY(-10px); /* Move up on hover */
  border-color: #5841D8; /* Change border color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .DemoGenderStylesTitle {
      font-size: 20px; /* Smaller font size on smaller screens */
      margin-bottom: 30px; /* Less margin */
  }

  .gender-card img {
      max-width: 150px; /* Smaller images on smaller screens */
  }
}

@media (max-width: 480px) {
  .DemoGenderStylesContainerGenderChoice {
      flex-direction: column; /* Stack cards vertically on very small screens */
  }

  .gender-card {
      width: 100%; /* Full width cards on very small screens */
  }
}
