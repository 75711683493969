  .WeekWorkoutStylesScrollContainer {
    padding: 10px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 100px;
  }
  
  .WeekWorkoutStylesContentContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .WeekWorkoutStylesSecondContainer {
    width: 100%;
  }

  .WeekWorkoutStylesBlockContainer {
    position: sticky;
    top: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 850px;
  }


  @media (max-width: 1200px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutStylesContentContainer {
        width: 100%;
    }
  }

  .FirstLayerWeekWorkoutScreenContentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
   }

   .WeekWorkoutShowMobile {
    display: none;
   }

   .WeekWorkoutStylesBlockIconContainer {
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
   }

  .WeekWorkoutStylesExcelIconContainer {
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WeekWorkoutStylesButtonModalToggle {
    display: flex;
    margin-top: 20px;
    transition: transform 0.2s;
  }

  .WeekWorkoutStylesButtonModalToggle:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }

  @media (max-width: 950px) {
    .WeekWorkoutHideMobile {
      display: none;
    }
    .WeekWorkoutShowMobile {
      display: flex;
    }
    .WeekWorkoutStylesExcelIconContainer {
      display: none;
    }
    .WeekWorkoutStylesButtonModalToggle {
      display: none;
    }
  }

  @media (max-width: 400px) { /* Adjust the max-width as needed */
    .LottieShowMobile {
      display: block; /* or 'inline-block', depending on your layout */
      width: 20px;
    }
    .LottieHideMobile {
      display: none;
    }
  }

  .WeekWorkoutStylesAddMargin {
    margin-top: 20px;
  }

  @media (max-width: 520px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutNoWorkoutsMessage {
      text-align: center;
    }
  }

  .WeekWorkoutStylesAddWidth {
    width: auto;
  }

  .WorkoutStylesAddExerciseButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .WorkoutStylesAddExerciseButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F7F8FA;
    border-radius: 10px;
    align-items: center;
    width: 100%;
    transition: transform 0.2s;
  }

  .WorkoutStylesAddExerciseButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }
  
  .WeekWorkoutStylesBackButtonContainer{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WeekWorkoutStylesButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }

  .WeekWorkoutStylesAddMarginTopButton {
    margin-top: 20px;
  }

  .WeekWorkoutStylesSaveButton {
    margin-top: 10px;
    background-color: black;
    padding: 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: 600;
  }

  .WeekWorkoutStylesSaveButtonTopPage{
    margin-right: 60px;
  }

  .WorkoutStylesEditIconContainer {
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WeekWorkoutStylesWorkoutContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .WeekWorkoutStylesTitleContainer {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .WeekWorkoutStylesSemaineDateContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-grow: 1;
  }

  .WeekWorkoutStylesWeekAndArrowsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;  
  }

  .WeekWorkoutStylesTitle {
    font-size: 25px;
    color: black;
    width: 330px;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutStylesTitle {
      font-size: 18px;
      width: 245px;
    }
  }

  .week-prefix {
    display: inline;
}

/* Hide the prefix on screens smaller than 520px */
@media screen and (max-width: 520px) {
    .week-prefix {
        display: none;
    }
}

@media screen and (max-width: 520px) {
  .WeekWorkoutStylesTitle {
      width: 160px;
  }
}
  
  .WeekWorkoutStylesDateContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    background-color: black;
    padding: 10px;
    border-radius: 15px;
  }
  
  .WeekWorkoutStylesDateDayName {
    font-size: 15px;
    color: #FFFF00;
    margin-bottom: 5px;
  }
  
  .WeekWorkoutStylesDateMonthName {
    font-size: 20px;
    font-weight: bold;
    color: #FFFF00;
  }

  .ProgramCreationStylesEditIconFake {
    width: 30px;
    height: 25px;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .WeekWorkoutStylesArrowButtonContainer {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutStylesArrowButtonContainer {
      margin-left: 5px;
      margin-right: 5px;
    }
  }

  @media (max-width: 520px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutStylesArrowButtonContainer {
      width: 20px;
      height: 20px;
      margin-left: 2px;
      margin-right: 2px;
    }
  }
  
  .WeekWorkoutStylesArrowButton {
    width: 12px;
    height: 22px;
  }

  @media (max-width: 768px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutStylesArrowButton {
      width: 10px;
      height: 17px;
    }
  }

  @media (max-width: 520px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutStylesArrowButton {
      width: 7px;
      height: 14px;
    }
  }

  .WeekWorkoutStylesNotesDisplayOnly {
    margin-left: 10px;
    margin-right: 5px;
    font-weight: 300;
    font-size: 13px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    resize: none;
    text-align: center;
    vertical-align: auto;
  }

  .WeekWorkoutStylesNotes {
    padding: 10px 15px; /* Adequate padding for touch targets */
    font-size: 13px; /* Comfortable font size on mobile */
    border: 1px solid #b6b6b6; /* Subtle border */
    border-radius: 10px; /* Rounded corners */
    outline: none; /* Remove default focus outline */
    width: 100%; /* Full width with padding considered */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    transition: border-color 0.3s; /* Smooth transition for border color */
    resize: none;
  }

    .WeekWorkoutStylesNotes:focus {
      border-color: #2D2D2D; /* Highlight color when focused */
    }

    .WorkoutStylesDeleteIconContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 10px;
    }

  .WeekWorkoutStylesInputField {
    position: absolute;
    bottom: -100px; /* Position it below the icon */
    right: 0; /* Align the input field's right edge with the container's right edge */
    left: 100; /* Align the input field's left edge with the container's left edge */
    width: 300px; /* Let it naturally fill the space between the right and left edges */
    padding: 8px 12px; /* Padding inside the input */
    margin-top: 8px; /* Space above the input field */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #fff;
    transition: border-color 0.3s, box-shadow 0.3s;
    z-index: 10; /* Ensure the input field is above other elements */
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .WeekWorkoutStylesInputField:focus {
    border-color: black;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
    outline: none;
  }
  
  .WeekWorkoutStylesDetailedSet {
    font-size: 12px;
  }
  
  .WeekWorkoutStylesDetailedSetContainer {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .WeekWorkoutStylesWorkoutSetContainer {
    display: flex;
    flex-direction: column;
    margin-top: 7px;
    margin-bottom: 7px;
  }
  
  .WeekWorkoutStylesRowContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .WeekWorkoutStylesExerciseContainerWorkout {
    width: 100%;
  }
  
  .WeekWorkoutStylesRowContainerMainSet {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-width: 0.1px;
    border-radius: 10px;
    background-color: #F7F8FA;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .WeekWorkoutStylesRowContainerMainSetAddGrabIcon {
    cursor: grab;
  }

  @media (max-width: 430px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutBackgroundColorGreen {
      border: 1px solid #5CB85C;
    }
    .WeekWorkoutBackgroundColorRed {
      border: 1px solid #D9534F;
    }
  }
  
  .WeekWorkoutStylesCheckboxContainer {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .WeekWorkoutStylesMainSet {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
  }

  @media (max-width: 520px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutStylesMainSet {
      font-size: 12px;
    }
  }
  
  .WeekWorkoutStylesContainertitre {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 40px;
    background-color: black;
    padding: 10px;
    border-radius: 15px;
  }
  
  .WeekWorkoutStylesCommentInput {
    border-color: gray;
    border-width: 1px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    height: 100px;
    background-color: white;
    border-radius: 10px;
    /* Adjust text alignment for multiline input */
    text-align: start;
    vertical-align: top;
  }
  
  .WeekWorkoutStylesFakeLottie {
    font-weight: 300;
    font-size: 13px;
    color: black;
  }

  @media (max-width: 520px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutStylesFakeLottie {
      font-size: 10px;
      font-weight: 600;
    }
  }
  
  .WeekWorkoutStylesFakeLottieContainer {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .WeekWorkoutHideVeryTinyMobile {
    display: flex;
  }

  @media (max-width: 430px) { /* Adjust 768px to the breakpoint you need */
    .WeekWorkoutHideVeryTinyMobile {
      display: none;
    }
  }
  
  .WeekWorkoutStylesMainSetContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .WeekWorkoutStylesInputContainer {
    display: flex;
    justify-content: center;
  }
  
  .WeekWorkoutStylesHeaderContainerWorkout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .WeekWorkoutStylesHeaderSetContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .WeekWorkoutStylesRPEColumnContainerWorkout {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    margin-left: 7px;
    flex-grow: 0.2;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }
  
  .WeekWorkoutStylesDisplayRPE {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    font-weight: bold;
  }

  .WeekWorkoutStylesExerciseColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    flex-grow: 0.4;
    flex-basis: 5px; /* Default width */
    justify-content: center;
    margin-left: 4px;
    min-width: 1px;
  }

  .WeekWorkoutStylesMainExerciseContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .WeekWorkoutStylesSetColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    flex-grow: 0.2;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }

  .WeekWorkoutStylesFakeXContainer {
    display: flex;
    align-items: center;
    opacity: 0;
  }

  .WeekWorkoutStylesXContainer {
    display: flex;
    align-items: center;
    opacity: 1;
  }
  
  .WeekWorkoutStylesRepsColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    margin-left: 7px;
    flex-grow: 0.2;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }
  
  .WeekWorkoutStylesWeightColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    margin-left: 7px;
    flex-grow: 0.3;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }

  .WeekWorkoutStylesReposColumnContainer {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    margin-left: 7px;
    flex-grow: 0.3;
    flex-basis: 5px; /* Default width */
    justify-content: center;
  }

  .WeekWorkoutStylesNotesContainer {
    display: flex;
    flex-direction: column;
    margin-right: 7px;
    margin-left: 7px;
    flex-basis: 5px; /* Default width */
    justify-content: center;
    flex-grow: 0.6;
  }

  /* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .WeekWorkoutStylesNotesContainer {
    display: none;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1230px) {
  .WeekWorkoutStylesNotesContainer {
    flex-grow: 0.8; /* Largest for large screens */
  }
}

.WeekWorkoutStylesVideoContainer {
  display: flex;
  flex-direction: column;
  margin-right: 7px;
  margin-left: 7px;
  flex-grow: 0.1;
  flex-basis: 5px; /* Default width */
  justify-content: center;
}

.WeekWorkoutStylesFakeVideoIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 5px;
    opacity: 0;
}
  
  .WeekWorkoutStylesHearderNotesContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .WeekWorkoutStylesTitleComponents {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
  }
  
.WeekWorkoutStylesInput::-webkit-inner-spin-button,
.WeekWorkoutStylesInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.WeekWorkoutStylesInput {
  -moz-appearance: textfield; /* This line is for Firefox */
  /* Existing styles */
  border: 1px solid gray;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  color: black;
  padding: 10px 5px; /* Adequate padding for touch targets */
  font-size: 15px; /* Comfortable font size on mobile */
  border: 1px solid #ccc; /* Subtle border */
  border-radius: 10px; /* Rounded corners */
  outline: none; /* Remove default focus outline */
  width: 100%; /* Full width with padding considered */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  transition: border-color 0.3s; /* Smooth transition for border color */
}
  
  .WeekWorkoutStylesInput:focus {
    border-color: #2D2D2D; /* Highlight color when focused */
  }
  
  /* Placeholder styling */
  .WeekWorkoutStylesInput::placeholder {
    color: #888; /* Placeholder text color */
  }

  .WeekWorkoutStylesSelector {
    border-color: rgb(177, 177, 177);
  }

  .WeekWorkoutStylesLottieChecked {
    width: 30px; /* Set a specific size or use relative units like % or vw */
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .WeekWorkoutStylesButtonBackModal {
    position: 'absolute'; 
      top: '10px';
      left: '10px'; 
      border: 'none'; 
      cursor: 'pointer';
      display: 'flex';
      align-Items: 'center';
      justify-Content: 'center';
      margin: 0;
      padding: 0;
  }
  .WeekWorkoutStylesModalVideo{
    width: 100%;
    height: 100%;

  }
  @media screen and (max-width: 768px) { /* Adjust the max-width as needed */
    .WeekWorkoutStylesButtonBackModal {
      position: absolute;
      top: 5px; /* Adjust as needed */
      left: 5px; /* Adjust as needed */
      z-index: 1000;
      
    }
    .WeekWorkoutStylesModalVideo {
      width: 100%;
      height: auto;
      margin-top: 40px;
      
      /* Adjust other styling as needed */
    }
  
  }

  .WeekWorkoutStylesSpinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-left-color: white;
    animation: spin 1s ease infinite;
    text-align: center;
}
.WeekWorkoutStylesModalContainerExcel {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Aligns children vertically */
  align-items: center; /* Aligns children horizontally */
  height: 100%; /* Ensure modalContent takes full height of the modal */
  text-decoration: none;
}


.WeekWorkoutStylesModalbackArrowExcel {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.WeekWorkoutStylesModalDLContainerExcel {
  margin-top: 30px;
  text-decoration: none;
  

}
.WeekWorkoutStylesModalIMContainerExcel {
  margin-top: 30px;
  

}

.downloadButton {
  display: inline-block;
  padding: 10px 15px;
  margin: 10px 0;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 5px;
  text-decoration: none; /* Removes underline from the link */
}

.downloadButton:hover {
  background-color: rgba(0, 0, 0, 0.686);
  text-decoration: none;
}

.WeekWorkoutStylesModalMessageImportContainerExcel{
  margin-top: 30px;


}
.importSuccessModal {
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  padding: 20px;
  background-color: #5CB85C;
  color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  justify-content: center;
  align-items: center;
}
