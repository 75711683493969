.Logincontainer {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.LoginSignUpContainer {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  padding-bottom: 20px;
  width: 35%;
}

@media (max-width: 1024px) {                 
  .Logincontainer, .LoginSignUpContainer {
    width: 80%;
  }
}

.LoginScreenForgotPasswordText {
    color: gray;
    margin-top: 15px;
}

.LoginScreenDescription {
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
}

@media (max-width: 550px) {                 
  .LoginScreenDescription {
    text-align: center;
    }
}

.LoginStylesImageContainer {
    display: none;
    flex: 1;
    background-size: cover;
    background-position: center;
    border-radius: 20px; /* Rounded corners */
    border-bottom-left-radius: 20px; /* Rounded corners */
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.1); /* Shadow effect */
    max-height: 90vh; /* Optional: Ensures the image container doesn't exceed the viewport height */
    overflow: hidden;
    margin-left: 30px;
    margin-right: 50px;
  }

  @media screen and (min-width: 1024px) {
    .LoginStylesImageContainer {
      display: flex; /* or 'block', depending on how you've styled it */
      max-width: 600px; /* Or whatever maximum width you prefer */
    }
  }

  .sideImage {
    width: 100%;
    height: 100%;
    opacity: 0; /* Initial opacity */
    transition: opacity 1s ease-in-out; /* Smooth transition for the opacity */
}

.sideImage-visible {
    opacity: 1; /* Full opacity when the image is visible */
}

.LoginStylesSSOContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}

.LoginStylesButtonSignInGoogle {
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  transition: transform 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: dotted;
  border-width: 1px;
  border-color: gray;
}

.LoginStylesButtonSignInGoogle:hover {
  transform: translateY(-3px); /* Slight lift on hover */
}

.LoginStylesGoogleOrTitle {
  display: flex;
  font-size: 14px;
  justify-content: center;
  color: rgb(182, 182, 182);
}

.LoginStylesOrMessageLine {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
}

.LoginStylesOrMessageLine::before,
.LoginStylesOrMessageLine::after {
  content: '';
  flex-grow: 1;
  background: rgb(208, 208, 208); /* Or any color you want */
  height: 1px; /* Adjust the height for thicker lines */
  margin: 0 10px; /* Adjust spacing around the text */
}

.LoginStylesGoogleIcon {
  margin-left: 7px;
  width: 22px;
  height: 22px;
}