.ProfileCreationGenderOptionContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
/* Adjustments for mobile screens */
@media screen and (max-width: 480px) {
  .ProfileCreationGenderOption {
    padding: 8px 12px; /* Slightly smaller padding on mobile */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

.ProfileCreationGenderOption {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px; /* Adequate padding */
  border: 1px solid #ccc; /* Solid border with color */
  border-radius: 10px; /* Rounded corners */
  margin: 5px; /* Add some space between options */
  cursor: pointer; /* Cursor pointer for better UX */
  transition: all 0.3s ease; /* Smooth transition for interactions */
}

.ProfileCreationGenderOption:hover {
  border-color: black;
}

.ProfileCreationGenderOptionSelected {
  border-color: black;
}

.button.active {
  background-color: black;
  color: white;
}



  .ProfileCreationGenderText {
    font-Size: 16;
    color: 'black';
  }

  .ProfileCreationMaleContainer {
    flex: 1;
    margin-right: 4;
  }

  .ProfileCreationFemaleContainer {
    flex: 1;
    margin-left: 4;
  }

  .ProfileCreationLogoContainer {
    align-items: 'center';
  }

  .ProfileCreationLogo {
    width: 40px;
    height: 40px;
    margin-top: 20;
  }