.confirmationModalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .confirmationModalContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 35px;
    border-radius: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  .confirmationModalButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  
  .confirmationModalTitle {
    margin-bottom: 20px;
    text-align: center;
  }

  .confirmationModalButtonYes {
    color: red;
  }

  .confirmationModalButton {
    padding: 15px 15px; /* Adequate padding for touch targets */
    font-size: 15px; /* Comfortable font size on mobile */
    border: 1px solid transparent; /* Subtle border */
    border-radius: 10px; /* Rounded corners */
    outline: none; /* Remove default focus outline */
    width: 100%; /* Full width with padding considered */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    transition: border-color 0.3s; /* Smooth transition for border color */
    cursor: pointer; /* Cursor change to indicate clickability */
    margin: 5px;
}

.confirmationModalButton:hover {
  border-color: #919191; /* Highlight color when focused */
}  

.confirmationModalButtonYes:hover {
  border-color: rgb(254, 86, 86);
}