.CategoryPageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
  padding: 30px; /* Added padding */
}

.CategoryPageHeaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
}

.CategoryPageLogoContainer {
  margin-bottom: 15px;
}

.CategoryPageLogo {
  width: 50px; /* Smaller logo */
  height: auto;
}

.CategoryPageTitle {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 10px;
}

.CategoryPageSubTitle {
  font-size: 20px;
}

.CategoryPageSearchSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

@media (max-width: 1024px) {
  .CategoryPageSearchSection {
    justify-content: center;
    align-items: center;
  }
}

.CategoryPageTitleSelection {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: bold;
}

.CategoryPageButtonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; /* Center buttons */
  gap: 10px;
}

.CategoryPageButton {
  padding: 8px 16px;
  border: 1px solid #000;
  background-color: transparent;
  color: black;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 5px; /* Rounded corners */
}

.CategoryPageButton:hover {
  background-color: black;
  color: white;
}

.CategoryPageFooter {
  margin-top: 30px;
}

.CategoryPageButtonNext {
  width: 180px;
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  font-weight: bold;
  padding: 10px 0; /* Added padding for better touch target */
}

.CategoryPageActive {
  background-color: black;
  color: white;
}

@media (max-width: 400px) {
  .CategoryPageWelcome {
    padding: 10px;
  }

  .CategoryPageButton {
    width: 100%; /* Full width on mobile */
    min-width: 120px;
  }

  .CategoryPageButtonNext {
    width: 100%;
  }
}
