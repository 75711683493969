/* CalendarComponent.css */
.react-calendar {
  background-color: white;   /* Fond calendrier */
  border: 1px solid lightgray; /* Bordure Calendrier */
  border-radius: 15px;
  font-family: Arial, Helvetica, sans-serif;
}

@media (max-width: 1200px) { 
  .react-calendar {
    border: 1px solid transparent;
    width: 100%;
  }
}


.react-calendar__navigation {
  background-color: #005B96; /* Fond header (mois et fleches navig) */
  border-radius: 12px;
  overflow: hidden;
}

.react-calendar__tile--active {
  background-color: #005B96 !important; /* Fond jour selectionné */
  color: white;
  border-radius: 10px; 
}

.react-calendar__navigation button {
  color: white;
  font-weight: bold;
}

/* Style for days (including weekends) */
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  color: black; /* Black text for all days */
  font-weight: normal; /* Normal font weight for all days */
   padding: 5px;
}

/* Remove underlines from weekdays */
.react-calendar__month-view__weekdays__weekday {
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Bold font for clarity */
}

.react-calendar__navigation button:hover {
  background-color: #005B96 !important;
}

/* If you have a label for month/year and want to change its hover effect */
.react-calendar__navigation label:hover {
  text-decoration: underline; /* Example: Underline on hover */
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
}

/* Hover effect for days */
.react-calendar__month-view__days__day:hover {
  border-radius: 10px; /* Rounded corners on hover */
  background-color: #f2f2f2 !important; /* Light grey background on hover */
}

/* Style for marked dates */
.markedDate {
  background-color: purple;
  color: black;
}

.react-calendar__month-view__days__day::after {
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

/* Green dot for completed dates */
.dateCompleted::after {
  background-color: green;
}

/* Gray dot for not completed dates */
.dateNotCompleted::after {
  background-color: grey;
}

/* Red dot for failed dates */
.dateFailed::after {
  background-color: orange;
}
.dateallFailed::after {
  background-color: red;
}

/* Remove default blue background on active/focus */
.react-calendar__tile--active,
.react-calendar__tile:enabled:focus,
.react-calendar__tile:enabled:active {
  background-color: #005B96 ; /* Override to black background */
  color: white;
}

/* Remove outline on focus for all interactive tiles */
.react-calendar__tile--hasActive,
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--hasActive:enabled:active {
  outline: none;
  
}

.react-calendar__navigation button:active,
.react-calendar__navigation button:focus,
.react-calendar__navigation label:active,
.react-calendar__navigation label:focus {
  color: white;
  background-color: #005B96 !important; /* Blue background */
  outline: none; /* Remove the outline that browsers might apply on focus */
}

.context-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  width: 150px;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.context-menu div {
  margin-bottom: 10px; /* Adds space between the options */
  padding: 5px; /* Padding for each option */
  cursor: pointer; /* Changes the cursor to indicate clickable items */
  font-size: 14px; /* Adjust the font size as needed */
}

.context-menu div:hover {
  background-color: #f0f0f0; /* Light grey background on hover */
  border-radius: 5px; /* Optional: Rounded corners on hover */
}

.date-overlay {
  background-color: red;
}

.react-calendar__navigation button[disabled] {
  pointer-events: none;
  color: grey; /* Optional: change the color to indicate it's disabled */
}

.react-calendar__tile--now {
  background-color: transparent; /* Transparent background for the current date */
  color: blue; /* Blue text color for the current date */
}

.react-calendar__tile--now:hover {
 /* Black background on hover */
  color: blue; /* White text color on hover */
}

/* Hover effect on the selected current date should do nothing */
.react-calendar__tile--now.react-calendar__tile--active:hover {
  background-color: #005B96 !important; /* Keep the black background on hover */
  color: white; /* Keep the white text color on hover */
}
.react-calendar__tile--active:hover {
  background-color: #005B96 !important; /* Keep the black background on hover */
  color: white; /* Keep the white text color on hover */
}

.selectedDate {
  background-color: #005B96 !important; /* Or any color you prefer */
  color: white;
  /* Other styling as needed */
}

.react-calendar__tile--active .dateCompleted::after {
  background-color: green;
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

/* Gray dot for not completed dates on selected date */
.react-calendar__tile--active .dateNotCompleted::after {
  background-color: grey;
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

/* Red dot for failed dates on selected date */
.react-calendar__tile--active .dateFailed::after {
  background-color: orange;
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

.react-calendar__tile--active .dateallFailed::after {
  background-color: red;
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

/* Green dot for completed dates within the selected week */
.dateInSelectedWeek .dateCompleted::after {
  background-color: green;
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

/* Gray dot for not completed dates within the selected week */
.dateInSelectedWeek .dateNotCompleted::after {
  background-color: grey;
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

/* Red dot for failed dates within the selected week */
.dateInSelectedWeek .dateFailed::after {
  background-color: orange;
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

.dateInSelectedWeek .dateallFailed::after {
  background-color: red;
  content: '';
  display: block;
  margin: auto;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: relative;
  top: 2px;
}

.dateInSelectedWeek {
  background-color: #f2f2f2; /* Change this to your preferred hover color */
  border-radius: 10px; /* Rounded corners on hover */
}
