.centeredView {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modalView {
    margin: 20px;
    background-color: white;
    border-radius: 20px;
    padding: 35px;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
  }
  
  .modalTitle {
    margin-bottom: 20px;
    text-align: center;
  }
  
  .modalButton {
    color: black;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 10px;
    transition: background-color 0.3s;
  }
  
  .modalButton:hover {
    background-color: #f0f0f0;
  }
  