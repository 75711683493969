.LandingPageStylesMainContainer {
    display: flex;
    flex-direction: column;
    background-image: radial-gradient(circle at center, #dfe6fa 10%, #ffffff 80%);
    height: auto; /* This will make sure that your container fills the whole viewport height */
    padding-left: 7%;
    padding-right: 7%;
  }
  .LandingPageStylesMainContainerPayment {
    display: flex;
    flex-direction: column;
    background-image: radial-gradient(circle at top, #fdfcfd 10%, #ffffff 80%);
    height: auto; /* This will make sure that your container fills the whole viewport height */
    padding-left: 7%;
    padding-right: 7%;
  }

  .VioletButton {
    background-color: #5841D8; /* Gray background */
    color: white; /* Dark text color for contrast */
    border: none;
    padding: 12px 22px;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.2s;
    width: 100%;
    font-weight: 500;
  }
  .VioletButtonAddPadding {
    padding: 20px;
  }

  .VioletButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
  }


  .LandingPageStylesMainContainerOtherPages {
    display: flex;
    flex-direction: column;
    background-image: radial-gradient(circle at center, #ffffff 10%, #ffffff 80%);
    height: auto; /* This will make sure that your container fills the whole viewport height */
    padding-left: 7%;
    padding-right: 7%;
  }

.LandingPageStylesHeaderContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.LandingPageStylesLogo {
    width: 150px;
    cursor: pointer;
}
.LandingPageStylesLogoPayment {
    width: 100px;
    cursor: pointer;
}

.LandingPageStylesLogoFooter {
    width: 100px;
}

.LandingPageStylesNavigationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 60%;
}

.LandingPageStylesFirstSectionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}

.LandingPageStylesFirstSectionContainerLastRow{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: column;
    
}

.LandingPageStylesFourthSectionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.LandingPageStylesFifthSectionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.LandingPageStylesSecondSectionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
}

.LandingPageStylesTextContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.LandingPageStylesTextContainerLastRow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    text-align: center;
}

.LandingPageStylesTextContainerPayment {
    display: flex;
    flex-direction: column;
    width: 90%;
}

.LandingPageStylesTextContainerPayment2 {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.LandingPageStylesTextContainerSecondSectionSecond {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 40%;
}

.LandingPageStylesTextContainerSecondSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
}

.LandingPageStylesDescriptionContainerSecondSection {
    width: 70%;
}

.LandingPageStylesImageCoach {
    width: 55%;
}
.LandingPageStylesIllustration {
    width: 100%;
}
.LandingPageStylesImageCoachPayment
 {
    width: 40%;
}
.LandingPageStylesImageCoachPayment2
 {
    width: 40%;
}
.LandingPageStylesImageCoachPayment3
{
    width: 40%;
}

.LandingPaymentStylesVideoContainer {
    display: flex;
    width: 40%;
}

.LandingPaymentStylesIllustrationContainer {
    display: flex;
    width: 60%;
    margin-left: 30px;
}

.LandingPageStylesVideoCoach
{
    width: 60%;
    margin-top: 40px;
}
.LandingPageStylesTitle {
    font-family: Futura;
    font-size: 50px;
    font-weight: 500;
    width: 100%;
}
.LandingPageStylesTitleLastRow {
    font-family: Futura;
    font-size: 50px;
    font-weight: 500;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
}
.LandingPageStylesTitleLittle {
    font-family: Futura;
    font-size: 50px;
    font-weight: 500;
    width: 100%;
   
}
.LandingPageStylesTitlePayment {
    font-family: Futura;
    font-size: 50px;
    font-weight: 500;
    width: 100%;
}

.LandingPageStylesTitlePayment2 {
    font-family: Futura;
    font-size: 50px;
    font-weight: 500;
    width: 100%;
}

.LandingPageStylesDescription {
    font-size: 17px;
    color: rgb(121, 121, 121);
    margin-top: 10px;
}
.LandingPageStylesDescriptionAddUnderline {
    font-size: 17px;
    color: rgb(121, 121, 121);
    text-decoration: underline;
}

.LandingPageStylesContainerButtonArrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.LandingPageStylesFirstIcon {
    width: 100px;
}
.LandingPageStylesFirstIconPayment {
    width: 50px;
}
.LandingPageStylesFirstIconPayment2 {
    width: 300px;
    margin-bottom: 10px;
}
.LandingPageStylesFirstIconPayment3 {
    width: 70px;
    margin-bottom: 10px;
}

.LandingPageStylesArrow {
    width: 25px;
    margin-left: 10px;
}

.LandingPageButtonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.LandingPageButtonsContainerPayment {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 50%;
}

.LandingPageButtonsContainerPayment2 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
}

.LandingPageStylesThirdSectionContainer {
    display: flex;
    background-image: linear-gradient(to right, #1b77b4, #6da8d2);
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    border-radius: 25px;
    width: 100%;
    margin-bottom: 80px;
    margin-top: 50px;
}

.LandingPageStylesThirdSectionContainerPayment {
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    border-radius: 25px;
    width: 100%;
    margin-bottom: 80px;
    margin-top: 180px;
}

.LandingPageStylesThirdSectionContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.LandingPageButtonsContainerFourthSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
}

.LandingPageStylesContainerText {
    font-size: 18px;
    color: white;
    font-weight: 600;
}
.LandingPageStylesContainerTextPayment {
    color: #5841D8;
    font-family: Futura;
    font-size: 50px;
    font-weight: lighter;
    width: 100%;
    text-align: center;
    margin-bottom: 50px;
}
.LandingPageStylesContainerTextPaymentQuote {
    color: #5841D8;
    font-family: Futura;
    font-size: 50px;
    font-weight: lighter;
    width: 100%;
    text-align: start;
    font-style: italic;
}
.LandingPageStylesContainerTextPayment2 {
    font-size: 25px;
    color: black;
    font-weight: lighter;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 20px;
    padding: 20px;
    font-family: Futura;
}

.LandingPageStylesContainerSubText {
    font-size: 15px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}
.LandingPageStylesContainerSubTextPayment {
    font-size: 15px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5841D8;;
}

.LandingPageStylesContainerIconAndText {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 20px;
}
.LandingPageStylesContainerIconAndTextPayment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LandingPageStylesAddColor {
    background-color: white;
    color: black;
    margin-left: 20px;
}
.LandingPageStylesAddColorPayment {
    background-color: white;
    color: #5841D8;
    margin-left: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    
}


.LandingPageStylesAddWidth {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LandingPageStylesContainerIconAndTextDescriptionTitle {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}
.LandingPageStylesContainerIconAndTextDescriptionTitlePayment {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    flex-direction: column;
}

.LandingPageStylesAddWidthLastButton {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.LandingPageStylesAddFont {
    font-size: 35px;
}

.LandingPageStylesAddMaring {
    margin-top: 30px;
}

@keyframes fadeInScroll {
    from {
        opacity: 0;
        transform: translateY(20px); /* Optional: add a little "slide up" effect */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.LandingPageStylesAddMaringPayment {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 150px;
    flex-direction: column;
    opacity: 0;
}

.fadeInVisible {
    animation: fadeInScroll 1s ease-out forwards;
}


.LandingPageStylesAddMaringPayment2 {
    margin-top: 100px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 100px;
    flex-direction: column;
}

.LandingPageStylesAddMargingTop {
    margin-top: 0px;
}

.LandingPageStylesAddMaringBottom {
    margin-bottom: 50px;
}
.LandingPageStylesAddMarginLeft {
    margin-left: 25px;
}

.LandingPageStylesAddHeight {
    height: 55px;
}

.LandingPageStylesTitleAddColor {
    color: #005B96;
}
.LandingPageStylesTitleAddColorPayment {
    color: #5841D8;
}

.LandingPageStylesContainerMenuMobileTextButton {
    text-decoration: dashed;
    transition: transform 0.2s;
}

.LandingPageStylesContainerMenuMobileTextButton:hover {
    transform: translateY(-3px); /* Slight lift on hover */
    text-decoration: none;
  }

.LandingPageStylesFooterContainerContent {
    text-align: center;
    margin: auto;
    display: inline-block;
    line-height: 1.5; /* Space between lines */
}

.LandingPageStylesFooterContainer {
    background-color: #000; /* Black background */
    color: #fff; /* White text */
    padding: 40px; /* Padding around the content */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 50px;
  }
  
  .LandingFooter-section {
    display: flex;
    flex-direction: column;
    margin: 10px;
    flex-basis: 20%; /* Adjust the width of each section */
}

.LandingFooter-section h5 {
    margin-bottom: 20px; /* Space below the section title */
}

/* Styling for buttons to match the <a> tags */
.LandingFooter-button {
    display: block;
    width: 100%; /* Make the button fill the container */
    background: none; /* Remove default background */
    color: #fff; /* White text, adjust if your background is different */
    border: none; /* Remove default border */
    text-align: left; /* Align text to the left like a link */
    padding: 0; /* Remove default padding */
    margin-bottom: 10px; /* Space between buttons */
    cursor: pointer;
}

.LandingPageFooterMessage {
    display: none;
}
.LandingPageStylesMenuMobileIcon {
    display: none;
}

.LandingFooter-button:hover {
    text-decoration: underline; /* Underline on hover, similar to the <a> hover effect */
}
  
  @media (max-width: 768px) {
    .LandingFooter-section {
      flex-basis: 50%; /* Stack columns on smaller screens */
    }
  }

  .HamburgerMenuIcon {
    display: none; /* Hide by default */
    cursor: pointer;
}

.MenuOverlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 50;
}

.CloseMenuIcon {
    display: none;
}

@media (max-width: 1024px) {
    .LandingPageStylesNavigationContainer {
        transition: transform 0.3s ease;
        width: 250px; /* Adjust the width of the side menu */
        height: 100%;
        position: fixed;
        top: 0;
        right: -250px; /* Start offscreen to the right */
        background-color: #FFF; /* Adjust background color as needed */
        z-index: 100;
        display: flex; /* Ensure the menu is flex */
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-top: 100px;
        padding-bottom: 20px; /* Add padding at the bottom */
    }
    .LandingPageStylesContainerMenuMobileTextButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px;
    }
    .LandingPageFooterMessage {
        display: block;
        width: 100%; /* Ensure it spans the full width if needed */
        text-align: center; /* Center the text */
        margin-top: auto; /* Push it to the bottom */
        font-size: 12px;
    }
    .LandingPageStylesMenuMobileIcon {
        display: block;
        width: 10px;
    }
    .LandingPageStylesNavigationContainer.Active {
        transform: translateX(-250px); /* Slide in */
    }
    .HamburgerMenuIcon {
        display: block; /* Show the hamburger menu icon on smaller screens */
        width: 40px;
    }
    .MenuOverlay.Active {
        display: block; /* Show overlay when menu is active */
    }
    .LandingPageStylesAddWidth {
        width: 90%;
    }
    .LandingPageStylesButtonNavigation {
        text-align: start;
        width: 90%;
        transition: transform 0.2s;
    }
    .LandingPageStylesFirstSectionContainer {
        flex-direction: column;
        justify-content: center;
    }
    .LandingPageStylesFourthSectionContainer {
        flex-direction: column;
    }
    .LandingPageStylesTextContainer {
        width: 100%;
        margin-bottom: 50px;
    }
    .LandingPageStylesTextContainerSecondSection {
        flex-direction: column;
        align-items: flex-start;
    }
    .LandingPageStylesDescriptionContainerSecondSection {
        width: 100%;
    }
    .LandingPageStylesTextContainerSecondSectionSecond {
        width: 100%;
        flex-direction: row;
        margin-bottom: 10px;
    }
    .LandingPageStylesAddMargingTop {
        margin-top: 20px;
    }
    .LandingPageStylesThirdSectionContainer {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 50px;
    }
    .LandingPageButtonsContainerPayment {
    width: 100%;
    }
    .LandingPageStylesThirdSectionContainerPayment {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: auto;
        padding-left: 10px;
        padding-right: 10px;
        margin-bottom: 20px;
        margin-top: 0px;
    }
    .LandingPageStylesFirstIconPayment2 {
        width: 180px;
        margin-bottom: 10px;
    }
    .LandingPageStylesContainerIconAndText {
        margin-left: 40px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .LandingPageStylesFirstIcon {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .LandingPageStylesContainerIconAndTextDescriptionTitle {
        justify-content: flex-start;
    }
    .LandingPageStylesImageCoach {
        order: 2; /* Move the image after the text container */
        width: 100%;
    }
    .LandingPageStylesContainerTextPayment {
        font-size: 40px;
        font-weight: lighter;
        margin-bottom: 20px;
    }
    .LandingPageStylesTitlePayment {
        font-size: 40px;
        justify-content: center;
        align-items: center;
        text-align: left; 
    }
    .LandingPageStylesTitlePayment2 {
        font-size: 40px;
        justify-content: center;
        align-items: center;
        text-align: left; 
    }
    .LandingPageStylesTextContainerPayment {
        justify-content: center;
        align-items: flex-start;
        text-align: center; 
        width: 100%;
    }
    .LandingPageStylesTextContainerPayment2 {
        justify-content: center;
        align-items: flex-start;
        text-align: center; 
        width: 100%;
    }
    .LandingPageStylesDescription {
        text-align: left;
    }
    .LandingPageStylesTextContainerLastRow {
        align-items: flex-start;
    }
    
    .LandingPageStylesVideoCoach
{
    width: 70%;
}
.LandingPageStylesAddMaringPayment {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0px;
    flex-direction: column;
}
.LandingPageStylesContainerTextPayment2 {
    font-size: 20px;
}
.LandingPageStylesTitleLittle {
    font-size: 40px;
    text-align: left;
   
}
.LandingPageStylesTextContainer {
    display: flex;
    flex-direction: center;
    text-align: left;
    justify-content: center;
}
.LandingPageButtonsContainerFourthSection {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.LandingPageStylesContainerTextPaymentQuote {
    font-size: 40px;  
    text-align: center;
}
    .LandingPageStylesImageCoachPayment
 {
    order: 2; /* Move the image after the text container */
    width: 100%;
}
.LandingPageStylesImageCoachPayment2
 {
    width: 50%;
}
.LandingPageStylesImageCoachPayment3
{
    width: 60%;
    margin-top: 40px;
}
.LandingPageStylesFirstIconPayment3 {
    width: 40px;
}
.LandingPageStylesAddMarginLeft {
    margin-left: 0px;
}
.LandingPageStylesFifthSectionContainer {
    flex-direction: column;
    
}

    .LandingPageStylesTextContainer {
        order: 1; /* Ensure the text container comes first */
    }
    .LandingPageStylesFooterContainer {
        justify-content: flex-start;
    }
    .LandingPageHiedOnMobile {
        display: none;
    }
    .LandingPageGoToLine {
        display: flex;
    }
    .CloseMenuIcon {
        display: flex;
        cursor: pointer;
        position: absolute;
        top: 20px; /* Adjust as needed */
        right: 20px; /* Adjust as needed */
        z-index: 101; /* Ensure it's above other menu items */
        width: 20px;
    }
    .LandingPageStylesAddColor {
        background-color: rgb(248, 248, 248);
    }
    .LandingPaymentStylesVideoContainer {
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .LandingPaymentStylesIllustrationContainer {
        display: flex;
        width: 100%;
        margin-top: 50px;
        margin-bottom: 50px;
        margin-left: 0px;
    }
}

@media (max-width: 768px) {
    .LandingPageStylesLogo {
        width: 120px;
    }
    .LandingPageStylesLogoPayment {
        width: 80px;
    }
    .HamburgerMenuIcon {
        display: block; /* Show the hamburger menu icon on smaller screens */
        width: 30px;
    }
    .LandingPageStylesAddMaringBottom {
        margin-bottom: 20px;
    }
    .LandingPageStylesTitle {
        font-size: 40px;
    }
    .LandingPageStylesAddFont {
        font-size: 30px;
    }
    .VioletButton {
        padding: 10px;
    }

}



.LandingPageStylesFooterContainer ul {
    list-style: none; /* Supprime les puces */
    padding: 0; /* Supprime le padding par défaut */
    margin: 0; /* Supprime la marge par défaut */
}

.LandingPageStylesFooterContainer li {
    margin: 0; /* Supprime la marge par défaut */
    padding: 0; /* Supprime le padding par défaut */
    /* Ajoutez d'autres styles ici si nécessaire pour s'adapter à votre design */
}

.LandingPageStylesPaymentTitlePricing {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    flex-direction: column;
}

.LandingPageStylesPaymentCardSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px 0;
    margin-top: 0px;
    background-image: url('../assets/BackgroundPricing.png');
    background-size: cover; /* Cover the entire card */
    background-position: center; /* Center the background image */
    }
  
  .PaymentCardTitle {
    font-size: 40px;
    font-weight: 500;
    font-family: Futura;
    margin-bottom: 60px;
    text-align: center;
  }

  .PaymentCardTitleFirst {
    font-size: 40px;
    font-weight: 500;
    font-family: Futura;
    margin-bottom: 40px;
    text-align: center;
  }
  
  .PaymentCard {
    width: 100%;
    max-width: 400px;
    background-color: white ;
    border-color: #5841D8;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    cursor: pointer;
  }

  .PaymentCard:hover {
    transform: translateY(-10px); /* Move up on hover */
  }

  .PaymentPriceContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
  }

  .PaymentPriceTextTitle {
    width: 100%;
    font-size: 15px;
    margin-bottom: 5px;
  }

  .PaymentPriceTextDescriptionText {
    width: 100%;
    font-size: 15px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 300;
  }

  .PaymentPriceBenefits {
    font-weight: 300;
  }
  
  .PaymentPrice {
    font-size: 50px;
    font-weight: bold;
    font-family: Futura;
  }

  .PaymentPriceOriginal {
    font-size: 50px;
    font-weight: bold;
    font-family: Futura;
    font-size: 35px;
    font-family: Futura;
    text-decoration: line-through; /* This creates the slash effect */
    color: #999; /* Grayed out look for the original price to emphasize the discount */
  }

  .LandingPageStylesPaymentContainer {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }

  .LandingPageStylesPaymentBenefitsPricingIcon {
    width: 20px;
  }
  
  .PaymentBenefits {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }

  .LandingPageStylesPaymentButtonContainer {
    display: flex;
    width: 100%;
  }
  
  .PaymentButton {
    background-color: #5841D8;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
  
    .PaymentPrice {
      font-size: 45px;
    }
  
    .PaymentPerMonth {
      font-size: 16px;
    }
  
    .PaymentBenefits li {
      font-size: 14px;
    }
  
    .PaymentButton {
      padding: 8px 16px;
      font-size: 14px;
    }
  }

  .LandingPageStylesPaymentButtonAddMargin {
    width: auto;
    transition: transform 0.2s;
    font-weight: 500;
  }

  .LandingPageStylesAddFullWidth {
    width: 100%;
  }

    /* Responsive adjustments */
    @media (max-width: 950px) {
        .LandingPageStylesPaymentCardSection {
          background-image: none;
        }
      }

    .LandingPaymentCoachProfileSlider {
        overflow: hidden;
        margin-bottom: 130px;
        margin-top: 80px;
    }

    .LandingPaymentSectionTitle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-bottom: 50px;
    }
    
    .LandingPaymentSliderContainer {
        width: 100%;
        overflow: hidden; /* Hide the overflow to ensure only the visible part of the slider is shown */
    }
    
    .LandingPaymentSlides {
        display: flex;
        animation: slide linear 30s infinite; /* Adjust the time to control the speed */
    }
    
    .LandingPaymentSlide {
        flex-shrink: 0; /* Prevent the images from shrinking */
        width: 350px; /* Adjust based on the size of your images */
        margin-right: 20px; /* Adjust if you want space between images */
    }
    
    @keyframes slide {
        0% { transform: translateX(0); }
        20% { transform: translateX(-50%); } /* Adjust these percentages */
        40% { transform: translateX(-100%); } /* so that the last keyframe */
        60% { transform: translateX(-150%); } /* corresponds to the last photo */
        80% { transform: translateX(-200%); }
        100% { transform: translateX(-250%); } /* This should be your last photo */
    }    

    @media (max-width: 768px) {
        @keyframes slide {
            0% { transform: translateX(0); }
            20% { transform: translateX(-200%); } /* Adjust these percentages */
            40% { transform: translateX(-400%); } /* so that the last keyframe */
            60% { transform: translateX(-600%); } /* corresponds to the last photo */
            80% { transform: translateX(-800%); }
            100% { transform: translateX(-1000%); } /* This should be your last photo */
        }  
      }
      