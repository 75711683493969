.PaymentLoginSignUpContainer {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.PaymentLogincontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 40%;
}

.PaymentTitleAndDescriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    text-align: center;
}

.PaymentLabel {
    font-size: 15px;
    color: black;
}

.PaymentLogo {
    width: 100px;
    height: 30px;
}

@media (max-width: 768px) {                 
    .PaymentLogincontainer, .PaymentLoginSignUpContainer {
        width: 80%;
    }
}

@media (max-width: 480px) {                 
    .PaymentLoginSignUpContainer {
        margin-top: 50px;
    }
}